import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer, redirect } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

import { getAuth } from 'src/auth/context';

async function getWorkspaceList(id: string) {
  await fetchAuthSession({ forceRefresh: true });
  const query = client.models.Workspace.list;
  const params = {
    filter: { userIds: { contains: id }, isDeleted: { eq: false } },
  };

  const workspaceList = await fetchAllData<
    Schema['Workspace']['type'],
    { filter: { userIds: { contains: string }; isDeleted: { eq: boolean } } }
  >(query, params);

  const workspaceIds = workspaceList?.data?.map((workspace) => workspace?.id);

  const workspaceUsersList = await Promise.all(
    workspaceIds?.map((workspaceId) =>
      client.queries.ListCognitoUsersByWorkspaceIdResolver({ workspaceId })
    )
  );

  return {
    workspaceList: workspaceList?.data,
    workspaceUsersList: workspaceUsersList?.map((workspaceUsers: any) => workspaceUsers?.data),
  };
}

export async function workspaceListLoader() {
  const user = await getAuth();

  if (!user.sub) {
    return redirect(paths.auth.signIn);
  }
  const promiseWorkspaceList = getWorkspaceList(user.sub);

  return defer({ promiseWorkspaceList });
}
