import { lazy } from 'react';

const RHFCode = lazy(() => import('src/components/hook-form/rhf-code'));
const RHFEditor = lazy(() => import('src/components/hook-form/rhf-editor'));
const RHFSelect = lazy(() => import('src/components/hook-form/rhf-select'));
const RHFUpload = lazy(() => import('src/components/hook-form/rhf-upload'));
const RHFTextField = lazy(() => import('src/components/hook-form/rhf-text-field'));
const RHFUploadBox = lazy(() => import('src/components/hook-form/rhf-upload-box'));
const RHFMultiSelect = lazy(() => import('src/components/hook-form/rhf-multi-select'));
const RHFUploadAvatar = lazy(() => import('src/components/hook-form/rhf-upload-avatar'));
const RHFPhoneInput = lazy(() => import('src/components/hook-form/rhf-phone-input'));
const RHFRadioGroup = lazy(() => import('src/components/hook-form/rhf-radio-group'));
const RHFMultiCheckbox = lazy(() => import('src/components/hook-form/rhf-multi-checkbox'));

// ----------------------------------------------------------------------

export const Field = {
  Code: RHFCode,
  Editor: RHFEditor,
  Select: RHFSelect,
  Upload: RHFUpload,
  Text: RHFTextField,
  UploadBox: RHFUploadBox,
  MultiSelect: RHFMultiSelect,
  UploadAvatar: RHFUploadAvatar,
  PhoneInput: RHFPhoneInput,
  RadioGroup: RHFRadioGroup,
  MultiCheckbox: RHFMultiCheckbox,
};
