import { useState } from 'react';

import {
  Box,
  Menu,
  Stack,
  Button,
  Dialog,
  Divider,
  MenuItem,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { useTranslate } from 'src/locales';
import { proIcon, businessIcon } from 'src/assets/icons/plan-icon';

import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';

// ----------------------------------------------------------------------

const chatOptions = [
  {
    id: 'gpt',
    label: 'GPT-4o',
  },
  {
    id: 'claude',
    label: 'Claude 3.5 Sonnet',
  },
];

type Props = {
  isFreePlan: boolean;
};

// ----------------------------------------------------------------------

export function ModelSelector({ isFreePlan }: Props) {
  const open = useBoolean();
  const { t, currentLang } = useTranslate();
  const mdUp = useResponsive('up', 'md');
  const router = useRouter();
  const params = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const workspaceId = params.workspaceId as string;

  const planHandler = async () => {
    router.replace(paths.workspace.plan.root(workspaceId));
  };

  // メニューを開く
  const filterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // メニューを閉じる
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ローカルストレージにモデルを保存
  const handleSetLocalStorage = (model: string) => {
    localStorage.setItem('model', model);
    handleClose();
  };

  // 現在のモデルを取得
  const currentOption =
    chatOptions.find((option) => option.id === localStorage.getItem('model')) ?? chatOptions[0];

  const lang = currentLang.value;

  return (
    <Stack direction="row" spacing={1} pl={2}>
      <Button
        onClick={filterClick}
        variant="text"
        sx={{
          maxWidth: '350px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          color="text.secondary"
        >
          {currentOption?.label}
        </Typography>
        <Iconify
          icon="carbon:chevron-sort"
          color="text.secondary"
          sx={{
            flexShrink: 0,
            ml: 0.5,
          }}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ width: '300px' }}
      >
        {chatOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => (isFreePlan ? open.onTrue() : handleSetLocalStorage(option.id))}
            sx={{ width: '100%' }}
          >
            <Typography
              variant="body2"
              noWrap
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: currentOption.id === option.id ? 'bold' : 'normal',
              }}
              color="text.secondary"
            >
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>

      <Stack direction="row" spacing={0.5} alignItems="center">
        <Iconify icon="solar:info-circle-bold" width={16} color="text.secondary" />
        <Typography variant="caption" color="text.secondary">
          {t('AIの種類を選択できます')}
        </Typography>
      </Stack>

      <Dialog open={open.value} onClose={open.onFalse} fullWidth>
        <DialogTitle>
          <Stack direction="row" alignItems="center" gap={1}>
            <Stack alignItems="center">
              <Iconify icon="eva:info-fill" />
            </Stack>
            {lang === 'ja' ? 'アップグレードが必要です' : 'Upgrade is Required'}
          </Stack>
        </DialogTitle>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <DialogContent>
          <Stack py={3} gap={3}>
            <Typography>
              {lang === 'ja'
                ? 'この機能を使うには、ProプランまたはBusinessプランの契約が必要です。'
                : 'To use this feature, you need to sign up for the Pro or Business plan.'}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" mr={7}>
              {mdUp && <Box sx={{ mr: 7 }}>{proIcon}</Box>}
              <Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h4" sx={{ color: 'black' }}>
                    {lang === 'ja' ? 'Proプラン' : 'Pro Plan'}
                  </Typography>
                  {mdUp && (
                    <Label variant="soft" color="info">
                      {lang === 'ja' ? '\\おすすめ/' : '\\Recommended/'}
                    </Label>
                  )}
                </Stack>
                <Typography variant="caption">
                  {lang === 'ja'
                    ? '複数のAIモデルの選択ができます。'
                    : 'You can select multiple AI models.'}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" mr={9}>
              {mdUp && <Box sx={{ mr: 5 }}>{businessIcon}</Box>}
              <Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h4" sx={{ color: 'black' }}>
                    {lang === 'ja' ? 'Businessプラン' : 'Business Plan'}
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  {lang === 'ja'
                    ? '複数のAIモデルの選択ができます。'
                    : 'You can select multiple AI models.'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button onClick={open.onFalse} color="inherit" variant="outlined">
              {lang === 'ja' ? '閉じる' : 'Close'}
            </Button>

            <Button
              onClick={() => {
                planHandler();
                open.onFalse();
              }}
              color="primary"
              variant="contained"
              sx={{ ml: 1 }}
            >
              {lang === 'ja' ? 'プランを詳しく見る' : 'View Plans'}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
