import Box from '@mui/material/Box';
import { Card, alpha, Stack, useTheme, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
import { CONFIG } from 'src/config-global';
import { bgGradient, stylesMode } from 'src/theme/styles';
import { proIcon, freeIcon, businessIcon } from 'src/assets/icons/plan-icon';

import { Iconify } from 'src/components/iconify';

// ----------------------------------------------------------------------

export function Section() {
  const theme = useTheme();
  const { t } = useTranslate();

  return (
    <Box sx={{ position: 'relative', height: '100vh', width: '100%' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          ...bgGradient({
            color: alpha(
              theme.palette.background.default,
              theme.palette.mode === 'light' ? 0.88 : 0.94
            ),
            imgUrl: '/assets/background/overlay_2.jpg',
          }),
          minWidth: 0,
          opacity: 0.24,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: `url(${CONFIG.site.basePath}/assets/background/background-3-blur.webp)`,
          [stylesMode.dark]: { opacity: 0.08 },
        }}
      />

      <Stack
        flexGrow={1}
        spacing={10}
        alignItems="center"
        justifyContent="center"
        sx={{
          position: 'relative',
          height: '100%',
          zIndex: 2,
        }}
      >
        <Box sx={{ m: 5 }}>
          <Stack spacing={3}>
            {businessIcon}
            <Typography variant="h3">{t('Businessプランにアップグレードする')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('ビジネスプランでは、より自由な体験が可能です。')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('生成AIと共に、より深いビジネスプランを作成しましょう。')}
            </Typography>
            <Box ml="auto">{proIcon}</Box>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('Businessプランで可能になることは、以下のとおりです。')}
            </Typography>
            <Card sx={{ p: 2 }}>
              <Stack direction="row" alignItems="center" spacing={1} width="100%">
                <Iconify icon="material-symbols:check" sx={{ color: 'text.primary' }} />
                <Typography variant="body2">{t('編集権限ユーザ数')}</Typography>
                <Box ml="auto">
                  <Typography variant="caption">{t('無制限')}</Typography>
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} width="100%">
                <Iconify icon="material-symbols:check" sx={{ color: 'text.primary' }} />
                <Typography variant="body2">{t('閲覧ユーザの招待')}</Typography>
                <Box ml="auto">
                  <Typography variant="caption">{t('無制限')}</Typography>
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} width="100%">
                <Iconify icon="material-symbols:check" sx={{ color: 'text.primary' }} />
                <Typography variant="body2">{t('エージェント呼び出し')}</Typography>
                <Box ml="auto">
                  <Typography variant="caption">{t('3種類まで')}</Typography>
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} width="100%">
                <Iconify icon="material-symbols:check" sx={{ color: 'text.primary' }} />
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2">{t('月間のコンテンツ生成数')}</Typography>
                </Stack>
                <Box ml="auto">
                  <Typography variant="caption">{t('無制限')}</Typography>
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} width="100%">
                <Iconify icon="material-symbols:check" sx={{ color: 'text.primary' }} />
                <Typography variant="body2">{t('JSONエクスポート機能')}</Typography>
              </Stack>
            </Card>
            {freeIcon}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
