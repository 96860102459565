import { defer } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';

async function getOrganization(organizationId: string) {
  const organization = await client.models.Organization.get({
    id: organizationId,
  });

  return organization?.data ?? undefined;
}

export const organizationDetailLoader = async (organizationId?: string) => {
  if (!organizationId) {
    return { organization: undefined };
  }

  return defer({
    organization: getOrganization(organizationId),
  });
};
