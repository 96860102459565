import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';

export async function AgentUpdateAction({ request }: { request: Request }) {
  switch (request.method) {
    case 'POST': {
      const data = (await request.json()) as Schema['Agent']['updateType'];

      const res = await agentUpdate(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
}

async function agentUpdate(data: Schema['Agent']['updateType']): Promise<ActionResponse<null>> {
  try {
    const { id, ...rest } = data;

    const agentRes = await client.models.Agent.update({
      id,
      ...rest,
    });

    if (agentRes.errors && agentRes.errors.length > 0) {
      throw new Error(agentRes.errors[0].message);
    }

    return {
      userMessage: {
        message: 'エージェントを更新しました。',
        severity: 'success',
      },
    };
  } catch (e) {
    return {
      userMessage: {
        message: e.message,
        severity: 'error',
      },
    };
  }
}
