import React, { lazy } from 'react';
import * as Sentry from '@sentry/react';
import {
  Navigate,
  matchRoutes,
  useLocation,
  useNavigationType,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router-dom';

import { CONFIG } from 'src/config-global';

import { authRoutes } from './auth';
import { dashboardRoutes } from './workspace';

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: 'https://676099f639488148743d16a8c5f0a77d@o1309262.ingest.us.sentry.io/4507796632502272',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  enableTracing: true,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// ----------------------------------------------------------------------

// Error
const Page500 = lazy(() => import('src/pages/error/500'));
const Page403 = lazy(() => import('src/pages/error/403'));
const Page404 = lazy(() => import('src/pages/error/404'));

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Navigate to={CONFIG.auth.redirectPath} replace />,
  },

  // Auth
  ...authRoutes,

  // Dashboard
  ...dashboardRoutes,

  // No match
  { path: '*', element: <Navigate to="/404" replace /> },
  { path: '500', element: <Page500 /> },
  { path: '404', element: <Page404 /> },
  { path: '403', element: <Page403 /> },
]);
