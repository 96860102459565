import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer, redirect } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';

import { getAuth } from 'src/auth/context';

async function getBusinessStatusHistory(businessModelId: string) {
  try {
    const res =
      await client.models.BusinessStatusHistory.listBusinessStatusHistoryByBusinessModelIdAndCreatedAt(
        {
          businessModelId,
        }
      );

    return res.data[0]?.organizationName || undefined;
  } catch (err) {
    return undefined;
  }
}

async function getBusinessModels(workspaceId: string) {
  const query = client.models.BusinessModel.listBusinessModelByWorkspaceIdAndCreatedAt;
  const params = {
    workspaceId,
    sortDirection: 'DESC',
  };

  const businessModels = await fetchAllData<
    Schema['BusinessModel']['type'],
    Schema['BusinessModel']['secondaryIndexes']['listBusinessModelByWorkspaceIdAndCreatedAt']['input']
  >(query, params);

  const businessModelsWithHistory = await Promise.all(
    businessModels?.data?.map(async (businessModel) => {
      try {
        const businessModelHistoriesQuery =
          client.models.BusinessModelHistory.listBusinessModelHistoryByBusinessModelIdAndVersion;
        const businessModelHistoriesParams = {
          businessModelId: businessModel?.id || '',
          sortDirection: 'DESC',
          limit: 1,
        };
        const businessModelHistories = await fetchAllData<
          Schema['BusinessModelHistory']['type'],
          Schema['BusinessModelHistory']['secondaryIndexes']['listBusinessModelHistoryByBusinessModelIdAndVersion']['input']
        >(businessModelHistoriesQuery, businessModelHistoriesParams);

        return businessModelHistories?.data[0] || null;
      } catch (error) {
        console.error(`Error fetching businessModelHistory for ID ${businessModel?.id}:`, error);
        return null;
      }
    }) || []
  );

  const filteredBusinessModelsWithHistory = businessModelsWithHistory.filter(
    (history) => history !== null
  );

  if (filteredBusinessModelsWithHistory.length === 0) {
    return { businessModels: [] };
  }

  const businessModelAndOrganizations = await Promise.all(
    filteredBusinessModelsWithHistory.map(async (businessModel) => {
      const organizationName = await getBusinessStatusHistory(businessModel.businessModelId);
      return { ...businessModel, organizationName };
    })
  );

  return {
    businessModels: businessModelAndOrganizations,
  };
}

async function getOrganizations(workspaceId: string) {
  const query = client.models.Organization.listOrganizationByWorkspaceIdAndCreatedAt;

  const params = {
    workspaceId,
    sortDirection: 'DESC',
  };

  const organizations = await fetchAllData<
    Schema['Organization']['type'],
    Schema['Organization']['secondaryIndexes']['listOrganizationByWorkspaceIdAndCreatedAt']['input']
  >(query, params);

  return { organizations: organizations?.data ?? [] };
}

async function getPlan(workspaceId?: string) {
  try {
    if (!workspaceId) {
      throw new Error('workspaceId is required');
    }

    const query = client.models.Plan.listPlanByWorkspaceId;

    const params = {
      workspaceId,
      filter: { status: { eq: 'Active' } },
    };

    const plans = await fetchAllData<
      Schema['Plan']['type'],
      Schema['Plan']['secondaryIndexes']['listPlanByWorkspaceId']['input']
    >(query, params);

    if (plans?.data && plans?.data?.length > 0) {
      return { plan: plans.data[0] };
    }
    return null;
  } catch (err) {
    console.error('Error in getPlan:', err);
    return null;
  }
}

async function getWorkspaceOwner(input: { id: Schema['Workspace']['type']['id'] }) {
  const { data } = await client.models.Workspace.get(input);

  return {
    workspaceOwner: data?.owner,
  };
}

const getWorkspaceAnalysis = async (workspaceId?: string) => {
  if (!workspaceId) {
    throw new Error('workspaceId is required');
  }
  const thisMonthData = await getWorkspaceAnalyticsData(workspaceId);
  return { workspaceAnalysisData: thisMonthData };
};

export const businessModelListLoader = async (workspaceId?: string) => {
  if (!workspaceId) {
    return redirect('/404');
  }

  const user = await getAuth();

  if (!user.sub) {
    return redirect(paths.auth.signIn);
  }

  const promiseBusinessModelList = Promise.all([
    getOrganizations(workspaceId),
    getPlan(workspaceId),
    getWorkspaceOwner({ id: workspaceId }),
    getBusinessModels(workspaceId),
    getWorkspaceAnalysis(workspaceId),
  ]);

  return defer({
    promiseBusinessModelList,
  });
};
