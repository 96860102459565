import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useParams, useRouter } from 'src/routes/hooks';

import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { useTranslate } from 'src/locales';

import { toast } from 'src/components/snackbar';
import { S3Avatar } from 'src/components/avatar';
import { varHover } from 'src/components/animate';
import { usePopover, CustomPopover } from 'src/components/custom-popover';

import { useAuthContext } from 'src/auth/hooks';
import { signOut } from 'src/auth/context/action';

// ----------------------------------------------------------------------

type Props = {
  data: {
    displayName: string;
    picture?: string;
    email: string;
    plan?: string;
    workspaceOwner?: string;
  };
};

export default function AccountPopover({ data }: Props) {
  const router = useRouter();
  const params = useParams();
  const workspaceId = params.workspaceId as string;

  const { user } = useAuthContext();
  const { t } = useTranslate();

  const popover = usePopover();

  const { checkUserSession } = useAuthContext();

  const OPTIONS = [
    {
      label: t('個人設定'),
      linkTo: `${paths.workspace.user.root(workspaceId)}?tab=profile`,
      isExternal: false,
    },
    {
      label: t('ワークスペース'),
      linkTo: `${paths.workspace.workspaceList.root(workspaceId)}`,
      isExternal: false,
    },
  ];

  if (user && user.id === data.workspaceOwner) {
    OPTIONS.push({
      label: t('マイプラン'),
      linkTo:
        import.meta.env.stripeCustomerPortal ??
        'https://billing.stripe.com/p/login/fZeeWg1AH28S5mUfYY',
      isExternal: true,
    });
  }

  const handleLogout = async () => {
    try {
      await signOut();

      await checkUserSession?.();
      popover.onClose();

      router.replace(paths.auth.signIn);
    } catch (err) {
      sentryCaptureError(err);
      toast.error(t('ログアウトに失敗しました。'));
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <S3Avatar displayName={data?.displayName} iconPath={data?.picture || ''} />
      </IconButton>
      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {data?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {data?.email}
          </Typography>

          <Typography variant="caption" sx={{ color: 'text.secondary' }} noWrap>
            {`${data.plan ?? 'Free'}${t('プラン')}`}
          </Typography>

          {user && user.id === data.workspaceOwner && (!data || data.plan !== 'Business') && (
            <Box
              sx={{
                bgcolor: 'rgba(89, 0, 255, 0.08)',
                p: 1,
                border: 'dashed 1px #5900FF',
                borderRadius: '4px',
              }}
            >
              <Link
                component={RouterLink}
                href={paths.workspace.plan.root(workspaceId)}
                variant="caption"
                sx={{ fontWeight: 'bold' }}
              >
                {t(`アップグレードする`)}
              </Link>

              <Typography variant="caption" sx={{ color: 'primary.main' }}>
                {t('ProまたはBusinessプランでBMGをさらに活用しましょう。')}
              </Typography>
            </Box>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) =>
            option.isExternal ? (
              <MenuItem key={option.label}>
                <Link
                  href={option.linkTo}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {option.label}
                </Link>
              </MenuItem>
            ) : (
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                {option.label}
              </MenuItem>
            )
          )}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          {t('ログアウト')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
