type InputData = string | string[];

export function convertLlmOutputToMarkdownList(input_data: InputData): string {
  function toList(data: InputData): string[] {
    if (!data) {
      return [];
    }

    if (Array.isArray(data)) {
      return data;
    }
    if (typeof data === 'string') {
      if (data.startsWith('[') && data.endsWith(']')) {
        return data
          .slice(1, -1)
          .split(',')
          .map((item) => item.trim().replace(/^['"]|['"]$/g, ''));
      }
      return [data];
    }
    return [String(data)];
  }

  function toSpanTag(item: string): string {
    const regex =
      /^<p\s*style\s*=\s*["']\s*color\s*:\s*(red|blue|purple|green)\s*;?\s*["']\s*>([\s\S]*?)<\/p>$/i;
    const match = item.match(regex);
    if (match) {
      const color = match[1].toLowerCase();
      const content = match[2];
      let backgroundColor = '';
      switch (color) {
        case 'red':
          backgroundColor = 'rgba(255, 86, 48, 0.12)';
          break;
        case 'blue':
          backgroundColor = '#CAFDF5';
          break;
        case 'purple':
          backgroundColor = '#DDCCFF';
          break;
        case 'green':
          backgroundColor = '#C8FAD6';
          break;
        default:
          return item;
      }
      return `<span style="background-color:${backgroundColor}">${content}</span>`;
    }
    return item;
  }

  const items: string[] = toList(input_data);

  const markdownList: string = items.map((item) => `- ${toSpanTag(item)}`).join('\n');

  return markdownList;
}
