import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer, redirect } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';

async function listMembersByWorkspaceId(input: {
  workspaceId: Schema['ListCognitoUsersByWorkspaceIdResolver']['args']['workspaceId'];
}) {
  const res = await client.queries.ListCognitoUsersByWorkspaceIdResolver(input);

  if (res.errors && res.errors.length > 0) {
    throw new Error('Failed to list users');
  }

  const users = res.data?.map((user) => ({
    familyName: user?.familyName,
    givenName: user?.givenName,
    email: user?.email,
    picture: user?.picture,
    id: user?.id,
  }));

  const memberRes = await Promise.all(
    (users ?? []).map(async (user) => {
      const role = await getUserRole({ workspaceId: input.workspaceId, userId: user.id ?? '' });

      return {
        ...user,
        role,
      };
    })
  );

  return {
    members: memberRes,
  };
}

async function getWorkspaceOwner(input: { id: Schema['Workspace']['type']['id'] }) {
  const { data } = await client.models.Workspace.get(input);
  return {
    workspaceOwner: data?.owner,
  };
}

async function getPlan(workspaceId?: string) {
  try {
    if (!workspaceId) {
      throw new Error('workspaceId is required');
    }

    const query = client.models.Plan.listPlanByWorkspaceId;

    const params = {
      workspaceId,
      filter: { status: { eq: 'Active' } },
    };

    const plans = await fetchAllData<
      Schema['Plan']['type'],
      Schema['Plan']['secondaryIndexes']['listPlanByWorkspaceId']['input']
    >(query, params);

    if (plans.data && plans.data.length > 0) {
      return plans.data[0];
    }
    return null;
  } catch (err) {
    console.error('Error in getPlan:', err);
    return null;
  }
}

const getWorkspaceAnalysis = async (workspaceId?: string) => {
  if (!workspaceId) {
    throw new Error('workspaceId is required');
  }
  const thisMonthData = await getWorkspaceAnalyticsData(workspaceId);
  return { workspaceAnalysisData: thisMonthData };
};

async function getUserRole(input: {
  workspaceId: Schema['WorkspaceUser']['type']['workspaceId'];
  userId: Schema['WorkspaceUser']['type']['userId'];
}) {
  const { data } = await client.models.WorkspaceUser.listWorkspaceUserByUserId({
    userId: input.userId,
  });

  const filteredData = data.filter((item) => item.workspaceId === input.workspaceId);
  return filteredData[0].role ?? null;
}

export const memberListLoader = async (workspaceId?: string) => {
  if (!workspaceId) {
    return redirect('/404');
  }

  const getWorkspaceOwnerRes = getWorkspaceOwner({ id: workspaceId });

  const listMembersRes = listMembersByWorkspaceId({ workspaceId });

  const planRes = { plan: await getPlan(workspaceId) };

  const thisMonthData = getWorkspaceAnalysis(workspaceId);

  const promiseMembers = Promise.all([
    listMembersRes,
    getWorkspaceOwnerRes,
    planRes,
    thisMonthData,
  ]);

  return defer({ promiseMembers });
};
