import { useMemo } from 'react';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';

import { SvgColor } from 'src/components/svg-color';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  organization: icon('ic_organization'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const params = useParams();
  const { workspaceId } = params;
  const { user } = useAuthContext();
  const { t } = useTranslate();

  const businessModelId = sessionStorage.getItem('businessModelId');
  const businessModelHistoryId = sessionStorage.getItem('businessModelHistoryId');

  const isBusinessModel = businessModelId && businessModelHistoryId;

  const data = useMemo(() => {
    const items = [
      {
        title: t('ビジネスモデル'),
        path: isBusinessModel
          ? paths.workspace.edit(workspaceId as string, businessModelId, businessModelHistoryId)
          : paths.workspace.businessModel(workspaceId as string),
        icon: ICONS.tour,
      },
      {
        title: t('メンバー'),
        path: paths.workspace.member.list(workspaceId as string),
        icon: ICONS.user,
      },
      // {
      //   title: 'エージェント',
      //   path: paths.workspace.agent.list(workspaceId as string),
      //   icon: ICONS.job,
      // },
      {
        title: t('組織'),
        path: paths.workspace.organization.root(workspaceId as string),
        icon: ICONS.job,
      },
    ];

    if (user) {
      items.push({
        title: t('プラン'),
        path: paths.workspace.plan.root(workspaceId as string),
        icon: ICONS.label,
      });
    }

    return [
      {
        subheader: '',
        items,
      },
    ];
  }, [user, workspaceId, t, isBusinessModel, businessModelId, businessModelHistoryId]);

  return data;
}
