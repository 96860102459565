import type { UpdateUserInput } from 'src/types/user';

import { updateUserAttributes } from 'aws-amplify/auth';

import { client } from 'src/utils/amplify-client-utils';

export const updateUserProfileAction = async (input: Omit<UpdateUserInput, 'email'>) => {
  if (input.givenName && input.familyName && input.phoneNumber) {
    const userAttributes: {
      [key: string]: string;
    } = {
      given_name: input.givenName,
      family_name: input.familyName,
      phone_number: input.phoneNumber,
    };
    if (input?.iconPath) {
      userAttributes.picture = input.iconPath;
    }

    const res = await Promise.all([
      updateUserAttributes({
        userAttributes,
      }),
      client.models.User.update({
        id: input.userId,
        iconPath: input?.iconPath,
        givenName: input.givenName,
        familyName: input.familyName,
        phoneNumber: input.phoneNumber,
        department: input.department,
        company: input.company,
        position: input.position,
        occupation: input.occupation,
        numberOfEmployees: input.numberOfEmployees,
        businesses: input.businesses,
        newBusinessSchedule: input.newBusinessSchedule,
      }),
    ]);

    return res[1];
  }

  const res = await client.models.User.update({
    id: input.userId,
    department: input.department,
    company: input.company,
    position: input.position,
    occupation: input.occupation,
    numberOfEmployees: input.numberOfEmployees,
    businesses: input.businesses,
  });

  return res;
};
