import { generateClient } from 'aws-amplify/data';
import { fetchAuthSession } from 'aws-amplify/auth';
import { type Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

export const client = generateClient<Schema>({
  headers: async (requestOptions) => {
    const session = await fetchAuthSession();

    return {
      Authorization: session?.tokens?.idToken?.toString() || '',
    };
  },
});
