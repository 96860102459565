import { useMemo, useEffect, useCallback } from 'react';
import { getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

import { useSetState } from 'src/hooks/use-set-state';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';

import { LoadingScreen } from 'src/components/loading-screen';

import { AuthContext } from './auth-context';

import type { AuthState } from './types';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const { state, setState } = useSetState<AuthState>({
    user: null,
    loading: true,
  });

  const checkUserSession = useCallback(async () => {
    try {
      const authSession = (await fetchAuthSession()).tokens;

      if (authSession) {
        const userAttributes = await fetchUserAttributes();
        const { userId: currentUser } = await getCurrentUser();

        const { idToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};

        const dbUser = await client.models.User.get({ id: currentUser });

        const workspaceUsers = await client.models.WorkspaceUser.listWorkspaceUserByUserId({
          userId: currentUser,
        });

        const workspaces = await Promise.all(
          (workspaceUsers.data ?? []).map(async (workspaceUser) => {
            const workspace = await client.models.Workspace.get({ id: workspaceUser.workspaceId });

            return {
              id: workspaceUser.workspaceId,
              isDeleted: !!workspace.data?.isDeleted,
              role: workspaceUser.role,
              name: workspace.data?.name,
              owner: workspace.data?.owner,
            };
          })
        );

        setState({
          user: {
            ...authSession,
            ...userAttributes,
            idToken,
            workspaces,
            ...dbUser.data,
            displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
          },
          loading: false,
        });

        axios.defaults.headers.common.Authorization = `Bearer ${authSession.accessToken}`;
      } else {
        setState({ user: null, loading: false });
        delete axios.defaults.headers.common.Authorization;
      }
    } catch (error) {
      console.error(error);
      setState({ user: null, loading: false });
    }
  }, [setState]);

  useEffect(() => {
    checkUserSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';
  const isAdmin = state.user?.organizationId === 'Admin';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user
        ? {
            ...state.user,
            id: state.user?.sub,
            accessToken: state.user?.accessToken?.toString(),
            displayName: `${state.user?.given_name} ${state.user?.family_name}`,
            organizationId: state.user.organizationId,
            idToken: state.user.idToken,
          }
        : null,
      checkUserSession,
      isAdmin,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
    }),
    [checkUserSession, state.user, status, isAdmin]
  );

  // ローディング中の表示
  if (state.loading) {
    return <LoadingScreen />;
  }

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
