import type { ActionResponse } from 'src/types/common';
import type { DeleteBusinessModelOutput } from 'src/sections/workspace/business-models/type';

import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

export const deleteBusinessModel = async (
  businessModelId: string
): Promise<ActionResponse<DeleteBusinessModelOutput>> => {
  try {
    const res = await client.mutations.BusinessModelDeleteResolver({
      businessModelId,
    });

    if (res.data?.message) {
      throw new Error(res.data.message);
    }

    if (res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }

    return {
      userMessage: {
        message: 'ビジネスモデルを削除しました。',
        severity: 'success',
      },
      data: {
        businessModelId,
      },
    };
  } catch (err) {
    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'ビジネスモデルの削除に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
};
