import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

async function getOrganizations(workspaceId?: string) {
  const query = client.models.Organization.listOrganizationByWorkspaceIdAndCreatedAt;

  const params = {
    workspaceId: workspaceId ?? '',
    sortDirection: 'DESC',
  };

  const organizations = await fetchAllData<
    Schema['Organization']['type'],
    Schema['Organization']['secondaryIndexes']['listOrganizationByWorkspaceIdAndCreatedAt']['input']
  >(query, params);

  return { organizations: organizations?.data ?? [] };
}

export const organizationLoader = async (workspaceId?: string) =>
  defer({
    organizations: getOrganizations(workspaceId),
  });
