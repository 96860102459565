import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json, type Params } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';
import { convertLlmOutputToMarkdownList } from 'src/utils/convertLlmOutputToMarkdownList';

import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';
import { updateCredit, checkThisMonthDataAndCredit } from 'src/actions/plan';
import { reviseCanvasByUserQueryCreditConsume } from 'src/assets/data/credit';

import { getAuth } from 'src/auth/context';

import type { ReviseBusinessModelFieldByUserQueryInput } from '../../../type';

enum BusinessModelFieldName {
  CustomerSegments = 'customerSegments',
  ValueProposition = 'valueProposition',
  Channels = 'channels',
  CustomerRelationships = 'customerRelationships',
  RevenueStreams = 'revenueStreams',
  KeyResources = 'keyResources',
  KeyActivities = 'keyActivities',
  KeyPartners = 'keyPartners',
  CostStructure = 'costStructure',
}

export const reviseBusinessModelCanvasFieldByUserQueryAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'POST': {
      const data = await request.json();
      const res = await reviseBusinessModelCanvasByUserQuery(data);
      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function reviseBusinessModelCanvasByUserQuery(
  data: ReviseBusinessModelFieldByUserQueryInput
): Promise<ActionResponse<{ creditShortage: boolean }>> {
  try {
    const thisMonthData = await getWorkspaceAnalyticsData(data.workspace_id);

    const checkRes = checkThisMonthDataAndCredit(
      thisMonthData,
      reviseCanvasByUserQueryCreditConsume
    );

    if (checkRes) {
      return checkRes;
    }

    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    // 生成
    const reviseCanvasRes = await axios.post(
      'revise-canvas-field-by-user-query',
      {
        keyPartners: data.canvas_values.keyPartners,
        keyActivities: data.canvas_values.keyActivities,
        keyResources: data.canvas_values.keyResources,
        customerSegments: data.canvas_values.customerSegments,
        valueProposition: data.canvas_values.valueProposition,
        customerRelationships: data.canvas_values.customerRelationships,
        channels: data.canvas_values.channels,
        costStructure: data.canvas_values.costStructure,
        revenueStreams: data.canvas_values.revenueStreams,
        userQuery: data.user_query,
        currentLangCountryCode: data.currentLangCountryCode,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.idToken.toString()}`,
        },
      }
    );

    const revisedBusinessModelData = reviseCanvasRes.data;

    // Historyの作成

    const latestBusinessModelHistory = await client.models.BusinessModelHistory.get({
      id: data.bmh_id,
    });

    const idea = latestBusinessModelHistory.data?.idea || '';
    const title = latestBusinessModelHistory.data?.title || '';

    const businessModelHistoryRes = await client.models.BusinessModelHistory.create({
      idea,
      title,
      businessModelId: data.bm_id,
      version: (latestBusinessModelHistory.data?.version ?? 0) + 1,
      owner: auth?.sub || '',
      workspaceId: data.workspace_id,
      sourceType: 'AI',
      createdAt: new Date().toISOString(),
      status:
        latestBusinessModelHistory.data?.status ||
        ('UnderConsideration' as Schema['BusinessModelStatus']['type']),
    });

    // DBへの保存

    const businessModelFieldsRes = await Promise.all(
      Object.values(BusinessModelFieldName).map((field) => {
        const convertedField = convertLlmOutputToMarkdownList(
          revisedBusinessModelData?.data[field] || ''
        );

        return client.models.BusinessModelField.create({
          businessModelHistoryId: businessModelHistoryRes?.data?.id || '',
          field,
          value: convertedField,
          changed: false,
          workspaceId: data.workspace_id,
        });
      })
    );

    if (!businessModelFieldsRes) {
      throw new Error('Failed to create businessModelFields');
    }

    // 要約の作成
    const summarizationRes = await client.mutations.GenerateSummarizationResolver({
      query: data.user_query,
      lang: data.currentLangCountryCode,
      llmModel: data.llmModel,
    });

    if (!summarizationRes) {
      throw new Error('Failed to generate summarization');
    }

    // 要約の保存
    await client.models.BusinessModelHistory.update({
      id: businessModelHistoryRes.data?.id || '',
      summarization: summarizationRes.data?.message || '',
    });

    const inputTokens = revisedBusinessModelData?.data.inputTokens || 0;
    const outputTokens = revisedBusinessModelData?.data.outputTokens || 0;

    await updateCredit(
      reviseCanvasByUserQueryCreditConsume,
      thisMonthData,
      data.workspace_id,
      inputTokens,
      outputTokens
    );

    return {
      userMessage: {
        message: 'ビジネスモデルフィールドをユーザの意見をもとに再生成しました。',
        severity: 'success',
      },
      redirectPath: paths.workspace.edit(
        data.workspace_id,
        data.bm_id,
        businessModelHistoryRes.data?.id || ''
      ),
    };
  } catch (err) {
    if (err.message && err.message?.includes('Credit is not enough')) {
      return {
        userMessage: {
          message: 'クレジットが不足しています',
          severity: 'error',
        },
        data: { creditShortage: true },
      };
    }

    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message:
          '申し訳ございませんが、ただいま混雑しております。しばらく時間をおいて実行してください。',
        severity: 'error',
      },
    };
  }
}
