import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { createWorkspace, deleteWorkspace, updateWorkspace } from 'src/actions/workspace-list';

export async function workspaceListAction({ request }: { request: Request }) {
  switch (request.method) {
    case 'DELETE':
      try {
        const { id, isDeleted, isDeletedCurrentWorkspace } = await request.json();

        const res = await deleteWorkspace({ id, isDeleted });

        let redirectPath;
        if (isDeletedCurrentWorkspace === true) {
          redirectPath = paths.auth.myWorkspaces;
        }

        return {
          data: res.data,
          userMessage: {
            message: 'ワークスペースの削除に成功しました',
            severity: 'success',
          },
          redirectPath,
        };
      } catch (err) {
        console.error(err);
        return json({ error: 'An error occurred processing the request' }, { status: 400 });
      }

    case 'PUT':
      try {
        const data = (await request.json()) as Schema['Workspace']['updateType'];
        const res = await updateWorkspace(data);
        return json(res);
      } catch (err) {
        console.error(err);
        return json({ error: 'An error occurred processing the request' }, { status: 400 });
      }

    case 'POST':
      try {
        const data = (await request.json()) as Schema['MultiWorkspaceResolver']['args'];

        const res = await createWorkspace(data);

        return json(res);
      } catch (err) {
        return json({ error: 'An error occurred processing the request' }, { status: 400 });
      }
    default:
      return json({ error: 'Method not allowed' }, { status: 405 });
  }
}
