import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { toast } from 'sonner';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

export function checkThisMonthDataAndCredit(
  thisMonthData: Schema['WorkspaceAnalytics']['type'],
  consumeCredit: number
): null | { userMessage: { message: string; severity: 'error' } } {
  if (!thisMonthData) {
    throw new Error('Failed to get workspace analytics data');
  }

  if (thisMonthData.credit + thisMonthData.addedCredit < consumeCredit) {
    return {
      userMessage: {
        message: '利用制限に到達しました。',
        severity: 'error',
      },
    };
  }

  return null;
}

export async function updateCredit(
  consumeCredit: number,
  thisMonthData: Schema['WorkspaceAnalytics']['type'],
  workspaceId: string,
  inputTokens: number,
  outputTokens: number
) {
  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth() + 1;

  if (thisMonthData.credit < consumeCredit) {
    await client.models.WorkspaceAnalytics.update({
      workspaceId,
      year: thisYear,
      month: thisMonth,
      count: thisMonthData.count + 1,
      inputToken: inputTokens + thisMonthData.inputToken,
      outputToken: outputTokens + thisMonthData.outputToken,
      credit: 0,
      addedCredit: thisMonthData.addedCredit - consumeCredit + thisMonthData.credit,
    });
  } else {
    await client.models.WorkspaceAnalytics.update({
      workspaceId,
      year: thisYear,
      month: thisMonth,
      count: thisMonthData.count + 1,
      inputToken: inputTokens + thisMonthData.inputToken,
      outputToken: outputTokens + thisMonthData.outputToken,
      credit: thisMonthData.credit - consumeCredit,
    });
  }
}

export async function proHandler(
  setLoadingDialog: (loading: boolean) => void,
  workspaceId: string,
  url: string,
  plan?: Schema['Plan']['type']
) {
  setLoadingDialog(true);
  try {
    const query = client.models.Plan.listPlanByWorkspaceId;

    const params = {
      workspaceId,
      filter: { plan: { eq: 'Pro' } },
    };

    const planList = await fetchAllData<
      Schema['Plan']['type'],
      Schema['Plan']['secondaryIndexes']['listPlanByWorkspaceId']['input']
    >(query, params);

    const isFirst = (planList?.data?.length ?? 0) === 0;

    const res = await client.mutations.StripeResolver({
      successUrl: url,
      cancelUrl: url.slice(1),
      current_plan: plan ? plan.plan : 'Free',
      new_plan: 'Pro',
      subscriptionId: plan && plan.stripeDealId ? plan.stripeDealId : 'mock',
      workspaceId,
      isFirst,
    });

    if (res.data?.message) window.location.href = res.data.message;

    if (res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }

    if (!res.data?.success) {
      throw new Error('エラーが発生しました。');
    }
  } catch (err) {
    toast.error('エラーが発生しました。');
  }
  setLoadingDialog(false);
}

export async function creditHandler(
  setLoadingDialog: (loading: boolean) => void,
  workspaceId: string,
  url: string
) {
  setLoadingDialog(true);
  try {
    const res = await client.mutations.StripeResolver({
      successUrl: url,
      cancelUrl: url.slice(1),
      current_plan: '',
      new_plan: 'Add',
      subscriptionId: 'mock',
      workspaceId,
      isFirst: false,
    });

    if (res.data?.message) window.location.href = res.data.message;

    if (res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }

    if (!res.data?.success) {
      throw new Error('エラーが発生しました。');
    }
  } catch (err) {
    toast.error('エラーが発生しました。');
  }
  setLoadingDialog(false);
}

export async function proDegradeHandler(
  setLoadingDialog: (loading: boolean) => void,
  workspaceId: string,
  plan: Schema['Plan']['type']
) {
  setLoadingDialog(true);
  try {
    const res = await client.mutations.StripeResolver({
      successUrl: '',
      cancelUrl: '',
      current_plan: plan.stripeItemId ?? '',
      new_plan: 'DegradeToPro',
      subscriptionId: plan.stripeDealId,
      workspaceId,
      isFirst: false,
    });

    if (res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }

    if (!res.data?.success) {
      throw new Error('エラーが発生しました。');
    }

    await client.models.Plan.update({
      id: plan.id,
      status: 'Canceled',
      endDate: new Date().toISOString(),
    });
  } catch (err) {
    toast.error('エラーが発生しました。');
  }
  setLoadingDialog(false);
}
