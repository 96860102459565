import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';
import { convertLlmOutputToMarkdownList } from 'src/utils/convertLlmOutputToMarkdownList';

import { reviseCanvasCreditConsume } from 'src/assets/data/credit';
import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';
import { updateCredit, checkThisMonthDataAndCredit } from 'src/actions/plan';

import { getAuth } from 'src/auth/context';

import type { ReviseBusinessModelFieldInput } from '../../../type';

enum BusinessModelFieldName {
  CustomerSegments = 'customerSegments',
  ValueProposition = 'valueProposition',
  Channels = 'channels',
  CustomerRelationships = 'customerRelationships',
  RevenueStreams = 'revenueStreams',
  KeyResources = 'keyResources',
  KeyActivities = 'keyActivities',
  KeyPartners = 'keyPartners',
  CostStructure = 'costStructure',
}

export const reviseBusinessModelCanvasFieldAction = async ({ request }: { request: Request }) => {
  switch (request.method) {
    case 'POST': {
      const data = await request.json();
      const res = await reviseBusinessModelCanvas(data);
      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function reviseBusinessModelCanvas(
  data: ReviseBusinessModelFieldInput
): Promise<ActionResponse<{ creditShortage: boolean }>> {
  try {
    const thisMonthData = await getWorkspaceAnalyticsData(data.workspace_id);

    const checkRes = checkThisMonthDataAndCredit(thisMonthData, reviseCanvasCreditConsume);

    if (checkRes) {
      return checkRes;
    }

    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    const reviseCanvasRes = await axios.post(
      'revise-canvas-field',
      {
        keyPartners: data.canvas_values.keyPartners,
        keyActivities: data.canvas_values.keyActivities,
        keyResources: data.canvas_values.keyResources,
        customerSegments: data.canvas_values.customerSegments,
        valueProposition: data.canvas_values.valueProposition,
        customerRelationships: data.canvas_values.customerRelationships,
        channels: data.canvas_values.channels,
        costStructure: data.canvas_values.costStructure,
        revenueStreams: data.canvas_values.revenueStreams,
        currentLangCountryCode: data.currentLangCountryCode,
        businessStatusHistory: data.businessStatusHistory,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.idToken.toString()}`,
        },
      }
    );

    const revisedBusinessModelData = reviseCanvasRes.data;

    // Historyの作成
    const latestBusinessModelHistory = await client.models.BusinessModelHistory.get({
      id: data.bmh_id,
    });

    const idea = latestBusinessModelHistory.data?.idea || '';
    const title = latestBusinessModelHistory.data?.title || '';

    const businessModelHistoryRes = await client.models.BusinessModelHistory.create({
      idea,
      title,
      businessModelId: data.bm_id,
      version: (latestBusinessModelHistory.data?.version ?? 0) + 1,
      owner: auth?.sub || '',
      workspaceId: data.workspace_id,
      sourceType: 'AI',
      createdAt: new Date().toISOString(),
      status:
        latestBusinessModelHistory.data?.status ??
        ('UnderConsideration' as Schema['BusinessModelStatus']['type']),
    });

    // DBへの保存
    const businessModelFieldsRes = await Promise.all(
      Object.values(BusinessModelFieldName).map((field) => {
        const convertedField = convertLlmOutputToMarkdownList(
          revisedBusinessModelData?.data[field] || ''
        );

        return client.models.BusinessModelField.create({
          businessModelHistoryId: businessModelHistoryRes?.data?.id || '',
          field,
          value: convertedField,
          changed: false,
          workspaceId: data.workspace_id,
        });
      })
    );

    if (!businessModelFieldsRes) {
      throw new Error('Failed to create businessModelFields');
    }

    const _query =
      client.models.BusinessModelField.listBusinessModelFieldByBusinessModelHistoryIdAndField;
    const _params = {
      businessModelHistoryId: data.bmh_id,
    };
    const latestBusinessModelFieldsRes = await fetchAllData<
      Schema['BusinessModelField']['type'],
      Schema['BusinessModelField']['secondaryIndexes']['listBusinessModelFieldByBusinessModelHistoryIdAndField']['input']
    >(_query, _params);

    const latestChangedSummary = latestBusinessModelFieldsRes.data
      ?.filter((item) => item?.changed)
      ?.map((item) => item?.changedSummary || '')
      .join('\n');

    // 要約の作成
    const queryJP = `
      以下の内容を一文で要約してください。

      ## ビジネスモデルキャンバスの変更内容
      ${latestChangedSummary ?? '未変更'}
    `;

    const queryEN = `
      Please summarize the following content in one sentence.

      ${latestChangedSummary}
    `;

    const summarizationRes = await client.mutations.GenerateSummarizationResolver({
      query: data.currentLangCountryCode === 'ja' ? queryJP : queryEN,
      lang: data.currentLangCountryCode,
      llmModel: data.llmModel,
    });

    if (!summarizationRes) {
      throw new Error('Failed to generate summarization');
    }

    // 要約の保存
    await client.models.BusinessModelHistory.update({
      id: businessModelHistoryRes.data?.id || '',
      summarization:
        `${data.businessStatusHistory?.changed ? revisedBusinessModelData?.data?.changedSummary : summarizationRes.data?.message}(AIによる全体再生成)` ||
        '',
    });

    // BusinessStatusHistoryの更新
    const businessStatusHistoryRes = await client.models.BusinessStatusHistory.update({
      id: data.businessStatusHistory?.id || '',
      changed: false,
    });

    if (!businessStatusHistoryRes) {
      throw new Error('Failed to update businessStatusHistory');
    }

    // すでに今月に入ってビジネスモデルを生成している場合は、カウンターをインクリメントする
    const inputTokens = revisedBusinessModelData?.data.inputTokens || 0;
    const outputTokens = revisedBusinessModelData?.data.outputTokens || 0;

    await updateCredit(
      reviseCanvasCreditConsume,
      thisMonthData,
      data.workspace_id,
      inputTokens,
      outputTokens
    );

    return {
      userMessage: {
        message: 'ビジネスモデルフィールドを再生成しました。',
        severity: 'success',
      },
      redirectPath: paths.workspace.edit(
        data.workspace_id,
        data.bm_id,
        businessModelHistoryRes.data?.id || ''
      ),
    };
  } catch (err) {
    if (err.message.includes('Credit is not enough')) {
      return {
        userMessage: {
          message: 'クレジットが不足しています',
          severity: 'error',
        },
        data: { creditShortage: true },
      };
    }

    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message:
          '申し訳ございませんが、ただいま混雑しております。しばらく時間をおいて実行してください。',
        severity: 'error',
      },
    };
  }
}
