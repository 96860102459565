import type { ActionResponse } from 'src/types/common';

import { json, type Params } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { generateKpiConsume } from 'src/assets/data/credit';
import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';
import { updateCredit, checkThisMonthDataAndCredit } from 'src/actions/plan';

import { getAuth } from 'src/auth/context';

import type {
  AddKpiInput,
  AddKpiOutput,
  UpdateKpiInput,
  DeleteKpiInput,
  UpdateKpiOutput,
  DeleteKpiOutput,
  GenerateKpiInput,
} from '../../type';

export const kpiAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'POST': {
      const data = Object.fromEntries(await request.formData()) as GenerateKpiInput;

      const res = await generateBusinessModelKpi(data);

      return json(res);
    }
    case 'PUT': {
      const data = Object.fromEntries(await request.formData()) as AddKpiInput;

      const res = await addBusinessModelKpi(data);

      return json(res);
    }
    case 'PATCH': {
      const data = Object.fromEntries(await request.formData()) as UpdateKpiInput;

      const res = await updateBusinessModelKpi(data);

      return json(res);
    }
    case 'DELETE': {
      const data = Object.fromEntries(await request.formData()) as DeleteKpiInput;

      const res = await deleteBusinessModelKpi(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function deleteBusinessModelKpi(
  data: DeleteKpiInput
): Promise<ActionResponse<DeleteKpiOutput>> {
  try {
    const res = await client.models.Kpi.delete(data);

    return {
      userMessage: {
        message: 'KPIを削除しました。',
        severity: 'success',
      },
      data: res.data,
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);
    return {
      userMessage: {
        message: 'KPIの削除に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}

async function updateBusinessModelKpi(
  data: UpdateKpiInput
): Promise<ActionResponse<UpdateKpiOutput>> {
  try {
    const kpiId = data.id;
    const { kpiContent, hypotheses } = data;

    const parsedHypotheses = JSON.parse(hypotheses);

    const res = await client.models.Kpi.update({
      id: kpiId,
      kpiContent,
      hypotheses: parsedHypotheses,
    });

    return {
      userMessage: {
        message: 'KPIを編集しました。',
        severity: 'success',
      },
      data: res.data,
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'KPIの編集に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}

async function addBusinessModelKpi(data: AddKpiInput): Promise<ActionResponse<AddKpiOutput>> {
  try {
    const { kpiContent, hypotheses } = data;
    const parsedHypotheses = JSON.parse(hypotheses);

    const res = await client.models.Kpi.create({
      businessModelId: data.businessModelId,
      workspaceId: data.workspaceId,
      kpiContent,
      hypotheses: parsedHypotheses,
      createdAt: new Date().toISOString(),
    });

    return {
      userMessage: {
        message: 'KPIを追加しました。',
        severity: 'success',
      },
      data: res.data,
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'KPIの追加に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}

async function generateBusinessModelKpi(data: GenerateKpiInput): Promise<ActionResponse<null>> {
  try {
    const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);

    checkThisMonthDataAndCredit(thisMonthData, generateKpiConsume);

    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    const {
      businessModelId,
      workspaceId,
      customerSegments,
      valueProposition,
      channels,
      customerRelationships,
      revenueStreams,
      keyResources,
      keyActivities,
      keyPartners,
      costStructure,
      currentLangCountryCode,
    } = data;

    const restOperation = await axios
      .post(
        'kpi',
        {
          customerSegments,
          valueProposition,
          channels,
          customerRelationships,
          revenueStreams,
          keyResources,
          keyActivities,
          keyPartners,
          costStructure,
          currentLangCountryCode,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.idToken.toString()}`,
          },
        }
      )
      .then((res) => res.data);

    await Promise.all(
      restOperation.data.kpi.map(async (kpi: any) => {
        await client.models.Kpi.create({
          businessModelId,
          workspaceId,
          kpiContent: kpi.kpiContent,
          hypotheses: kpi.hypotheses,
          createdAt: new Date().toISOString(),
        });
      })
    );

    const inputTokens = restOperation?.data.inputTokens || 0;
    const outputTokens = restOperation?.data.outputTokens || 0;

    await updateCredit(
      generateKpiConsume,
      thisMonthData,
      data.workspaceId,
      inputTokens,
      outputTokens
    );

    return {
      userMessage: {
        message: 'KPIを作成しました。',
        severity: 'success',
      },
    };
  } catch (err) {
    console.error(err);
    sentryCaptureError(err);

    return {
      userMessage: {
        message:
          '申し訳ございませんが、ただいま混雑しております。しばらく時間をおいて実行してください。',
        severity: 'error',
      },
    };
  }
}
