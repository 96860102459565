export const freeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="69" height="70" viewBox="0 0 69 70" fill="none">
    <path
      d="M63.7891 59.8665L62.791 62.9308C62.6859 63.246 62.4408 63.5087 62.1081 63.6138L59.0437 64.6294L62.1081 65.6275C62.4233 65.7326 62.6859 65.9777 62.791 66.3104L63.8066 69.3748L64.8047 66.3104C64.9098 65.9952 65.155 65.7326 65.4877 65.6275L68.5521 64.6119L65.4877 63.6138C65.1725 63.5087 64.9098 63.2635 64.8047 62.9308L63.7891 59.8665Z"
      fill="#6D2FC3"
    />
    <path
      d="M29.3958 26.9468C29.3958 24.1801 29.8336 21.5184 30.6566 19.0144C23.057 21.1857 17.4885 28.155 17.4885 36.4551C17.4885 46.4888 25.6135 54.6138 35.6472 54.6138C39.6221 54.6138 43.2994 53.318 46.2762 51.1467C36.4352 47.5044 29.3958 38.0486 29.3958 26.9468Z"
      fill="#FFEE00"
    />
    <path
      d="M53.7718 36.4546C53.7718 26.4209 45.6468 18.2959 35.6132 18.2959C33.8796 18.2959 32.2161 18.5586 30.6226 19.0138C29.8171 21.5179 29.3618 24.1795 29.3618 26.9462C29.3618 38.0656 36.3836 47.5039 46.2422 51.1461C50.795 47.8541 53.7543 42.4958 53.7543 36.4546H53.7718Z"
      fill="#FAAF3B"
    />
    <path
      d="M6.98242 13.3579C10.6284 13.3579 13.584 10.4023 13.584 6.75635C13.584 3.1104 10.6284 0.154785 6.98242 0.154785C3.33648 0.154785 0.380859 3.1104 0.380859 6.75635C0.380859 10.4023 3.33648 13.3579 6.98242 13.3579Z"
      fill="#29AAE1"
    />
  </svg>
);

export const proIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
    <path
      d="M61.9671 0.347656L61.1588 2.82953C61.0737 3.08481 60.8751 3.29754 60.6057 3.38264L58.1238 4.2052L60.6057 5.01358C60.8609 5.09868 61.0737 5.29723 61.1588 5.56669L61.9813 8.04856L62.7897 5.56669C62.8748 5.31141 63.0733 5.09868 63.3428 5.01358L65.8247 4.19102L63.3428 3.38264C63.0875 3.29754 62.8748 3.09899 62.7897 2.82953L61.9671 0.347656Z"
      fill="#6D2FC3"
    />
    <path
      d="M35.4451 9.46655C34.3531 9.46655 33.2752 9.58001 32.2257 9.73601C27.8434 10.4026 23.915 12.3881 20.8375 15.3096C16.753 19.1813 14.186 24.6415 14.186 30.7256C14.186 36.8097 16.5686 41.9295 20.4262 45.7729C21.8019 47.1485 23.3761 48.3257 25.0779 49.2759C28.1413 50.9919 31.6726 51.9705 35.4309 51.9705C38.5793 51.9705 41.5434 51.2755 44.2238 50.0417C47.2872 48.6377 49.9676 46.5387 52.0382 43.9434C54.9314 40.3127 56.6616 35.7319 56.6616 30.7256C56.6616 18.997 47.1454 9.48074 35.4167 9.48074L35.4451 9.46655Z"
      fill="#29AAE1"
    />
    <path
      d="M35.4438 9.46655C34.3518 9.46655 33.274 9.58001 32.2245 9.73601C31.2176 12.9554 31.3878 16.4442 32.7492 19.621C33.5576 21.5072 34.7489 23.2374 36.3231 24.5705C37.869 25.8611 39.7694 26.627 41.2869 27.9317C42.8611 29.2648 42.3647 31.534 42.7335 33.3918C43.1448 35.4624 44.1942 37.3487 45.499 38.9654C47.2434 41.1211 49.5409 42.7521 52.0653 43.9434C54.9585 40.3127 56.6887 35.7319 56.6887 30.7256C56.6887 18.997 47.1725 9.48074 35.4438 9.48074V9.46655Z"
      fill="#AFB6FF"
    />
    <path
      d="M27.1485 26.9387C26.4536 22.3862 24.0994 18.3869 20.8375 15.2952C16.753 19.1669 14.186 24.627 14.186 30.7112C14.186 36.7953 16.5686 41.9151 20.4262 45.7584C24.6525 40.2983 28.198 34.0156 27.1343 26.9529L27.1485 26.9387Z"
      fill="#AFB6FF"
    />
    <path
      d="M41.3284 42.0856C39.8819 40.8943 38.1658 40.0859 36.3221 39.6746C34.4926 39.2775 32.3937 38.9655 30.5784 39.5895C27.0186 40.8092 24.9622 44.8369 25.019 48.4533C25.019 48.7228 25.0615 48.9923 25.0899 49.2475C28.1532 50.9636 31.6846 51.9422 35.4428 51.9422C38.5913 51.9422 41.5554 51.2472 44.2358 50.0134C44.8031 47.0919 43.711 44.0285 41.3284 42.0714V42.0856Z"
      fill="#AFB6FF"
    />
    <path
      d="M7.26586 65.8125C11.0882 65.8125 14.1868 62.7139 14.1868 58.8916C14.1868 55.0693 11.0882 51.9707 7.26586 51.9707C3.44356 51.9707 0.344971 55.0693 0.344971 58.8916C0.344971 62.7139 3.44356 65.8125 7.26586 65.8125Z"
      fill="#FFEE00"
    />
  </svg>
);

export const businessIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="83" viewBox="0 0 100 83" fill="none">
    <path
      d="M29.5049 40.4458C31.6242 50.013 39.1456 57.9387 49.3621 60.0702C59.5785 62.2017 69.6357 57.9265 75.3932 49.9885C67.3572 49.4005 58.8068 48.0285 52.1428 46.6443C45.4911 45.26 37.0999 43.104 29.4927 40.4458H29.5049Z"
      fill="#626BFF"
    />
    <path
      d="M80.2805 34.7742C80.2805 32.9367 80.0599 31.1359 79.6802 29.3842C77.561 19.817 70.0395 11.8913 59.8231 9.75981C49.6066 7.62832 39.5494 11.9036 33.7919 19.8415C32.7384 21.287 31.832 22.8672 31.0847 24.5455C30.3742 26.1625 29.8107 27.8897 29.431 29.6905C29.0512 31.4912 28.8797 33.2919 28.892 35.0682C35.1149 37.4202 43.2366 39.8579 53.1346 41.9159C63.0325 43.9739 71.4482 44.9784 78.1 45.3091C78.8105 43.6921 79.374 41.9649 79.7537 40.1642C80.1335 38.3634 80.3049 36.5627 80.2927 34.7864L80.2805 34.7742Z"
      fill="#626BFF"
    />
    <path
      d="M79.6797 29.3842C80.0717 31.1359 80.2799 32.9367 80.2799 34.7741C89.7246 38.3389 94.8206 41.7321 95.1269 43.3491C94.1959 44.7088 88.1812 45.7868 78.0872 45.2968C71.4355 44.9661 63.0198 43.9616 53.1218 41.9036C43.2239 39.8456 35.1022 37.4079 28.8792 35.0559C19.4223 31.4912 14.3385 28.0979 14.0323 26.4809C14.9633 25.1212 20.978 24.0432 31.072 24.5332C31.8069 22.855 32.7257 21.287 33.7792 19.8292C21.4925 18.935 10.4431 19.8782 9.26707 25.4887C8.09107 31.0992 17.8665 36.3666 29.4917 40.4459C37.0989 43.1164 45.4901 45.2601 52.1418 46.6443C58.7936 48.0286 67.3563 49.4006 75.3922 49.9886C87.6789 50.8828 98.7283 49.9396 99.9043 44.3291C101.08 38.7186 91.3049 33.4512 79.6797 29.3719V29.3842Z"
      fill="#F05A24"
    />
    <path
      d="M87.6281 0.425537L86.9298 2.56928C86.8563 2.78977 86.6848 2.97352 86.4521 3.04702L84.3083 3.75752L86.4521 4.45577C86.6726 4.52927 86.8563 4.70076 86.9298 4.93351L87.6403 7.07725L88.3386 4.93351C88.4121 4.71301 88.5836 4.52927 88.8163 4.45577L90.9601 3.74527L88.8163 3.04702C88.5958 2.97352 88.4121 2.80202 88.3386 2.56928L87.6281 0.425537Z"
      fill="#6D2FC3"
    />
    <path
      d="M6.87763 56.2731C10.1792 56.2731 12.8556 53.5966 12.8556 50.2951C12.8556 46.9936 10.1792 44.3171 6.87763 44.3171C3.57609 44.3171 0.899658 46.9936 0.899658 50.2951C0.899658 53.5966 3.57609 56.2731 6.87763 56.2731Z"
      fill="#118C57"
    />
    <path
      d="M86.9308 82.9044C91.4637 82.9044 95.1383 79.2298 95.1383 74.697C95.1383 70.1641 91.4637 66.4895 86.9308 66.4895C82.398 66.4895 78.7234 70.1641 78.7234 74.697C78.7234 79.2298 82.398 82.9044 86.9308 82.9044Z"
      fill="#AFB6FF"
    />
  </svg>
);
