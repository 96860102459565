import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { getTokens } from 'src/auth/context';

type LoaderFunction = (args: LoaderFunctionArgs) => Promise<any>;

export const withAuthCheck: (loaderFunction: LoaderFunction) => LoaderFunction =
  (loaderFunction) => async (args) => {
    const isAuthenticated = await getTokens();
    if (!isAuthenticated) {
      return redirect(paths.auth.signIn);
    }

    return loaderFunction(args);
  };
