import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { getAuth } from 'src/auth/context';

export const businessModelOrganizationAction = async ({ request }: { request: Request }) => {
  switch (request.method) {
    case 'POST': {
      const data = Object.fromEntries(
        await request.formData()
      ) as Schema['Organization']['createType'];
      const res = await createOrganization(data);
      return json(res);
    }
    case 'PUT': {
      const data = Object.fromEntries(
        await request.formData()
      ) as Schema['Organization']['updateType'];
      const res = await updateOrganization(data);
      return json(res);
    }
    case 'DELETE': {
      const data = Object.fromEntries(
        await request.formData()
      ) as Schema['Organization']['deleteType'];
      const res = await deleteOrganization(data);
      return json(res);
    }
    default: {
      return null;
    }
  }
};

const createOrganization = async (data: Schema['Organization']['createType']) => {
  try {
    const auth = await getAuth();
    if (!auth?.idToken) {
      return {
        redirectPath: paths.auth.signIn,
      };
    }

    const res = await client.models.Organization.create({
      ...data,
      createdAt: new Date().toISOString(),
    });

    return {
      userMessage: {
        severity: 'success',
        message: '組織の作成に成功しました。',
      },
      data: res.data,
    };
  } catch (error) {
    sentryCaptureError(error);
    return {
      userMessage: {
        severity: 'error',
        message: '組織の作成に失敗しました。時間を置いて再度お試しください。',
      },
    };
  }
};

const updateOrganization = async (data: Schema['Organization']['updateType']) => {
  try {
    const auth = await getAuth();
    if (!auth?.idToken) {
      return {
        redirectPath: paths.auth.signIn,
      };
    }
    const res = await client.models.Organization.update({
      ...data,
    });

    return {
      userMessage: {
        severity: 'success',
        message: '組織の更新に成功しました。',
      },
      data: res.data,
    };
  } catch (error) {
    sentryCaptureError(error);

    console.error(error);
    return {
      userMessage: {
        severity: 'error',
        message: '組織の更新に失敗しました。時間を置いて再度お試しください。',
      },
    };
  }
};

const deleteOrganization = async (data: Schema['Organization']['deleteType']) => {
  try {
    const auth = await getAuth();
    if (!auth?.idToken) {
      return {
        redirectPath: paths.auth.signIn,
      };
    }
    await client.models.Organization.delete({
      id: data.id,
    });

    return {
      userMessage: {
        severity: 'success',
        message: '組織の削除に成功しました。',
      },
    };
  } catch (error) {
    sentryCaptureError(error);
    return {
      userMessage: {
        severity: 'error',
        message: '組織の削除に失敗しました。時間を置いて再度お試しください。',
      },
    };
  }
};
