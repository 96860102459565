import type { UpdateUserInput } from 'src/types/user';
import type { ActionResponse } from 'src/types/common';

import { json, type Params } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { CONFIG } from 'src/config-global';
import { updateUserProfileAction } from 'src/actions/user';

export const authUserProfileAction = async ({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'POST': {
      const data = (await request.json()) as UpdateUserInput;

      const res = await setUserProfile(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function setUserProfile(data: UpdateUserInput): Promise<ActionResponse<null>> {
  const isProd = import.meta.env.PROD;

  try {
    const { email, ...rest } = data;

    await updateUserProfileAction(rest);

    if (isProd) {
      await client.mutations.sendEmailResolver({
        senderAddress: 'business-model-generator@purpom-media-lab.com',
        toAddresses: ['prj_monitor_bmg-aaaaoxabtgfsaun7e3rhxw4h5u@purpommedialab.slack.com'],
        subject: `${data.givenName || ''} ${data.familyName || ''}さんが新規登録しました`,
        text: `${data.givenName || ''} ${data.familyName || ''}さんが新規登録しました。

登録内容の詳細は以下の通りです：
  •	名前: ${data.givenName || ''} ${data.familyName || ''}
	•	メールアドレス: ${email}
  •	電話番号: ${data.phoneNumber || '未入力'}
  •	部署: ${data.department || '未入力'}
  •	会社名: ${data.company || '未入力'}
  •	役職: ${data.position || '未入力'}
  •	職種: ${data.occupation || '未入力'}
  •	従業員数: ${data.numberOfEmployees || '未入力'}
  •	事業内容: ${data.businesses || '未入力'}
  •	新規事業を始める予定: ${data.newBusinessSchedule || '未入力'}
`,
      });
    }

    return {
      userMessage: {
        message: 'プロフィールを設定しました。',
        severity: 'success',
      },
      redirectPath: CONFIG.auth.redirectPath,
    };
  } catch (err) {
    sentryCaptureError(err);

    return {
      userMessage: {
        message: 'プロフィールの更新に失敗しました。時間を置いて再度お試しください。',
        severity: 'error',
      },
    };
  }
}
