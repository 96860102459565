import { useCallback } from 'react';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';

import { toast } from 'src/components/snackbar';

type ActionData = {
  redirectPath?: string;
  userMessage?: {
    severity: 'success' | 'error' | 'warning' | 'info';
    message: string;
  };
};

export const useHandleActionData = () => {
  const { push } = useRouter();

  const { t } = useTranslate();

  return useCallback(
    (actionData?: ActionData | null) => {
      if (actionData?.redirectPath) {
        push(actionData.redirectPath);
      }

      if (actionData?.userMessage) {
        toast[actionData.userMessage.severity](t(actionData.userMessage.message));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [push]
  );
};
