import { Stack, Button, Tooltip, Typography } from '@mui/material';

import { Iconify } from 'src/components/iconify';

type Props = {
  credits?: number;
  open?: boolean;
  handleClose?: () => void;
};

export default function CreditNotificationTooltip({ credits, open, handleClose }: Props) {
  const creditNotificationMessage =
    '使用回数の上限が近づいています。上限に達した場合、利用に制限がかかります。次の更新日に利用制限が解除されます。';

  return open && credits && credits <= 300 ? (
    <Tooltip
      title={
        <>
          {creditNotificationMessage}
          <br />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              size="small"
              onClick={handleClose}
              sx={{
                padding: '4px 8px',
                minWidth: 'auto',
                borderColor: '#FFFFF',
                borderWidth: '2px',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: '0.7rem',
                }}
              >
                警告を表示しない
              </Typography>
            </Button>
          </Stack>
        </>
      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'text.secondary',
            '& .MuiTooltip-arrow': {
              color: 'text.secondary',
            },
          },
        },
      }}
    >
      <Iconify
        color="#FFAB00"
        width={30}
        icon="eva:alert-triangle-fill"
        sx={{
          mr: 1,
        }}
      />
    </Tooltip>
  ) : null;
}
