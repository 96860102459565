import type { Theme, SxProps, Breakpoint } from '@mui/material/styles';

import Alert from '@mui/material/Alert';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { HeaderBase } from 'src/layouts/core/header-base';
import { LayoutSection } from 'src/layouts/core/layout-section';

import { Main, Content } from '../auth-signup';
import { Section } from '../../sections/workspace/plan/business-plan-section';

// ----------------------------------------------------------------------

export type AuthSplitLayoutProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

export function BusinessPlanLayout({ sx, children }: AuthSplitLayoutProps) {
  const mobileNavOpen = useBoolean();

  const mdUp = useResponsive('up', 'md');

  const layoutQuery: Breakpoint = 'md';

  const data = {
    langs: [
      {
        value: 'ja',
        label: 'Japanese',
        countryCode: 'JP',
      },
      {
        value: 'en',
        label: 'English',
        countryCode: 'GB',
      },
    ],
  };

  return (
    <LayoutSection
      headerSection={
        <HeaderBase
          disableElevation
          layoutQuery={layoutQuery}
          onOpenNav={mobileNavOpen.onTrue}
          data={data}
          slotsDisplay={{
            signIn: false,
            account: false,
            contacts: false,
            searchbar: false,
            workspaces: false,
            menuButton: false,
            localization: true,
            notifications: false,
          }}
          slots={{
            topArea: (
              <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                This is an info Alert.
              </Alert>
            ),
          }}
          slotProps={{ container: { maxWidth: false } }}
          sx={{ position: { [layoutQuery]: 'fixed' } }}
        />
      }
      footerSection={null}
      sx={sx}
      cssVars={{
        '--layout-auth-content-width': '420px',
      }}
    >
      <Main layoutQuery={layoutQuery}>
        {mdUp && <Section />}
        <Content layoutQuery={layoutQuery}>{children}</Content>
      </Main>
    </LayoutSection>
  );
}
