import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json } from 'react-router-dom';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';

import { commentCreditConsume } from 'src/assets/data/credit';
import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';
import { updateCredit, checkThisMonthDataAndCredit } from 'src/actions/plan';

import { getAuth } from 'src/auth/context';

import type { CommentOnBusinessModelInput } from '../../type';

export async function CommentOnBusinessModelAction({ request }: { request: Request }) {
  switch (request.method) {
    case 'POST': {
      const data = (await request.json()) as CommentOnBusinessModelInput;

      const res = await commentOnBusinessModel(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
}

async function commentOnBusinessModel(
  data: CommentOnBusinessModelInput
): Promise<ActionResponse<null>> {
  try {
    const auth = await getAuth();

    const { userId, agentId, agentName, businessModelId, workspaceId, ...rest } = data;

    const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);

    const checkRes = checkThisMonthDataAndCredit(thisMonthData, commentCreditConsume);

    if (checkRes) {
      return checkRes;
    }

    const commentRes = await axios.post(
      './comment-on-business-model',
      {
        ...rest,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.idToken?.toString()}`,
        },
      }
    );

    const comments = commentRes.data?.data.comments || {};

    await Promise.all(
      Object.entries(comments)?.map(([field, content]) => {
        if (Array.isArray(content)) {
          return Promise.all(
            content.map((text: string) => {
              const input: Schema['CommentCreateResolver']['args'] = {
                userId,
                agentId,
                workspaceId,
                businessModelId,
                agentName,
                target: field,
                text,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              } as any;

              return client.mutations.CommentCreateResolver(input);
            })
          );
        }
        return Promise.resolve();
      })
    );

    const inputTokens = commentRes.data?.inputTokens || 0;
    const outputTokens = commentRes.data?.outputTokens || 0;

    await updateCredit(
      commentCreditConsume,
      thisMonthData,
      data.workspaceId,
      inputTokens,
      outputTokens
    );

    return {
      userMessage: {
        severity: 'success',
        message: 'コメントしました',
      },
    };
  } catch (error) {
    return {
      userMessage: {
        severity: 'error',
        message:
          '申し訳ございませんが、ただいま混雑しております。しばらく時間をおいて実行してください。',
      },
    };
  }
}
