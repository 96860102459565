import type { ActionResponse } from 'src/types/common';

import { json } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';

import { checkThisMonthDataAndCredit } from 'src/actions/plan';
import { businessModelActionCreditConsume } from 'src/assets/data/credit';
import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';

import { getAuth } from 'src/auth/context';

type InputType = {
  workspaceId: string;
  businessModelId: string;
  prompt: string;
  keyPartners: string;
  keyActivities: string;
  keyResources: string;
  valueProposition: string;
  customerRelationships: string;
  channels: string;
  customerSegments: string;
  revenueStreams: string;
  costStructure: string;
  currentLangCountryCode: string;
  llmModel: string;
};

export async function BusinessModelAction({ request }: { request: Request }) {
  switch (request.method) {
    case 'POST': {
      const data = (await request.json()) as InputType;

      const res = await businessModelAction(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
}

async function businessModelAction(data: InputType): Promise<ActionResponse<null>> {
  const {
    workspaceId,
    businessModelId,
    prompt,
    currentLangCountryCode,
    llmModel,
    ...businessModelCanvas
  } = data;

  try {
    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);

    const checkRes = checkThisMonthDataAndCredit(thisMonthData, businessModelActionCreditConsume);

    if (checkRes) {
      return checkRes;
    }

    const res = await axios.post(
      'business-model-action',
      {
        prompt,
        currentLangCountryCode,
        ...businessModelCanvas,
        llmModel,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.idToken.toString()}`,
        },
      }
    );

    const isClaude = llmModel === 'claude';

    const createRes = await client.models.BusinessModelActionContent.create({
      workspaceId,
      businessModelId,
      title: isClaude ? (res.data?.title ?? '未設定') : (res.data?.data?.title ?? '未設定'),
      markdown: isClaude
        ? (res.data?.markdown ?? '未設定')
        : (res.data?.data?.markdown ?? '未設定'),
      prompt,
      inputTokens: res.data?.inputTokens ?? 0,
      outputTokens: res.data?.outputTokens ?? 0,
      createdAt: new Date().toISOString(),
    });

    if (createRes.errors && createRes.errors.length > 0) {
      throw new Error(createRes.errors[0].message);
    }

    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth() + 1;
    const inputTokens = res.data?.inputTokens || 0;
    const outputTokens = res.data?.outputTokens || 0;

    if (thisMonthData.credit + thisMonthData.addedCredit < 100) {
      await client.models.WorkspaceAnalytics.update({
        workspaceId: data.workspaceId,
        year: thisYear,
        month: thisMonth,
        inputToken: inputTokens + thisMonthData.inputToken,
        outputToken: outputTokens + thisMonthData.outputToken,
        credit: 0,
        addedCredit: thisMonthData.addedCredit - 100 + thisMonthData.credit,
      });
    } else {
      await client.models.WorkspaceAnalytics.update({
        workspaceId: data.workspaceId,
        year: thisYear,
        month: thisMonth,
        inputToken: inputTokens + thisMonthData.inputToken,
        outputToken: outputTokens + thisMonthData.outputToken,
        credit: thisMonthData.credit - 100,
      });
    }

    return {
      userMessage: {
        message: '生成に成功しました',
        severity: 'success',
      },
    };
  } catch (e) {
    return {
      userMessage: {
        message:
          '申し訳ございませんが、ただいま混雑しております。しばらく時間をおいて実行してください。',
        severity: 'error',
      },
    };
  }
}
