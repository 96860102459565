import type { SettingsState } from 'src/components/settings';
import type { NavSectionProps } from 'src/components/nav-section';
import type { Theme, SxProps, CSSObject, Breakpoint } from '@mui/material/styles';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { useMemo, Suspense, useState } from 'react';
import { Await, useLoaderData } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { iconButtonClasses } from '@mui/material/IconButton';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { varAlpha, stylesMode } from 'src/theme/styles';

import { bulletColor } from 'src/components/nav-section';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';

import { useAuthContext } from 'src/auth/hooks';

import { Main } from './main';
import { NavMobile } from './nav-mobile';
import { layoutClasses } from '../classes';
import { NavVertical } from './nav-vertical';
import { NavHorizontal } from './nav-horizontal';
import { HeaderBase } from '../core/header-base';
import { useNavData } from '../config-nav-workspace';
import { LayoutSection } from '../core/layout-section';

// ----------------------------------------------------------------------

export type WorkspaceLayoutProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  data?: {
    nav?: NavSectionProps['data'];
  };
};

export function WorkspaceLayout({ sx, children, data }: WorkspaceLayoutProps) {
  const { promisePlan } = useLoaderData() as {
    promisePlan: [
      { plan: Schema['Plan']['type'] },
      { workspaceOwner: string },
      { workspaceAnalysisData: Schema['WorkspaceAnalytics']['type'] },
      { workspaceList: Schema['Workspace']['type'][] },
      { workspaceUsersList: Schema['ListCognitoUsersByWorkspaceIdResolver']['type'][] },
    ];
  };

  const [creditAlertOpen, setCreditAlertOpen] = useState(true);
  const handleCloseCreditAlert = () => setCreditAlertOpen(false);

  const theme = useTheme();

  const { user } = useAuthContext();

  const mobileNavOpen = useBoolean();

  const settings = useSettingsContext();

  const navColorVars = useNavColorVars(theme, settings);

  const { currentLang } = useTranslate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navData = useNavData();

  const layoutQuery: Breakpoint = 'lg';

  const isNavMini = settings.navLayout === 'mini';

  const isNavHorizontal = settings.navLayout === 'horizontal';

  const isNavVertical = isNavMini || settings.navLayout === 'vertical';

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Await resolve={promisePlan}>
        {(resolved) => (
          <>
            <NavMobile
              data={navData}
              open={mobileNavOpen.value}
              onClose={mobileNavOpen.onFalse}
              cssVars={navColorVars.section}
              credit={
                resolved[2].workspaceAnalysisData.credit +
                resolved[2].workspaceAnalysisData.addedCredit
              }
              workspaceId={resolved[2].workspaceAnalysisData.workspaceId}
              plan={resolved[0] ? resolved[0].plan : null}
            />
            <LayoutSection
              /** **************************************
               * Header
               *************************************** */
              headerSection={
                <HeaderBase
                  layoutQuery={layoutQuery}
                  disableElevation={isNavVertical}
                  onOpenNav={mobileNavOpen.onTrue}
                  data={{
                    nav: navData,
                    langs: [
                      { value: 'ja', label: 'Japanese', countryCode: 'JP' },
                      { value: 'en', label: 'English', countryCode: 'GB' },
                    ],
                    account: {
                      displayName: `${user?.familyName} ${user?.givenName}`,
                      picture: user?.picture,
                      email: user?.email,
                    },
                    workspaces: user?.workspaces,
                    plan: resolved[0] ? resolved[2].workspaceAnalysisData.currentPlan : 'Free',
                    workspaceOwner: resolved[1].workspaceOwner,
                    credits: resolved[2].workspaceAnalysisData.credit,
                    // account: _account,
                    // contacts: _contacts,
                    // workspaces: user?.workspaces,
                    // notifications: _notifications,
                  }}
                  slotsDisplay={{
                    signIn: false,
                    account: true,
                    contacts: false,
                    searchbar: false,
                    workspaces: true,
                    menuButton: true,
                    localization: true,
                    notifications: false,
                  }}
                  creditAlertOpen={creditAlertOpen}
                  handleCreditAlertClose={handleCloseCreditAlert}
                  workspaceList={resolved[3].workspaceList}
                  workspaceUsersList={resolved[4].workspaceUsersList}
                  slots={{
                    topArea: (
                      <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                        This is an info Alert.
                      </Alert>
                    ),
                    bottomArea: isNavHorizontal ? (
                      <NavHorizontal
                        data={navData}
                        layoutQuery={layoutQuery}
                        cssVars={navColorVars.section}
                      />
                    ) : null,
                  }}
                  slotProps={{
                    toolbar: {
                      sx: {
                        [`& [data-slot="logo"]`]: {
                          display: 'none',
                        },
                        [`& [data-area="right"]`]: {
                          gap: { xs: 0, sm: 0.75 },
                        },
                        ...(isNavHorizontal && {
                          bgcolor: 'var(--layout-nav-bg)',
                          [`& .${iconButtonClasses.root}`]: {
                            color: 'var(--layout-nav-text-secondary-color)',
                          },
                          [theme.breakpoints.up(layoutQuery)]: {
                            height: 'var(--layout-nav-horizontal-height)',
                          },
                          [`& [data-slot="workspaces"]`]: {
                            color: 'var(--layout-nav-text-primary-color)',
                          },
                          [`& [data-slot="logo"]`]: {
                            display: 'none',
                            [theme.breakpoints.up(layoutQuery)]: { display: 'inline-flex' },
                          },
                          [`& [data-slot="divider"]`]: {
                            [theme.breakpoints.up(layoutQuery)]: {
                              display: 'flex',
                            },
                          },
                        }),
                      },
                    },

                    container: {
                      maxWidth: false,
                      sx: {
                        ...(isNavVertical && { px: { [layoutQuery]: 5 } }),
                      },
                    },
                  }}
                />
              }
              /** **************************************
               * Sidebar
               *************************************** */
              sidebarSection={
                isNavHorizontal ? null : (
                  <NavVertical
                    data={navData}
                    isNavMini={isNavMini}
                    layoutQuery={layoutQuery}
                    cssVars={navColorVars.section}
                    onToggleNav={() =>
                      settings.onUpdateField(
                        'navLayout',
                        settings.navLayout === 'vertical' ? 'mini' : 'vertical'
                      )
                    }
                    credit={
                      resolved[2].workspaceAnalysisData.credit +
                      resolved[2].workspaceAnalysisData.addedCredit
                    }
                    workspaceId={resolved[2].workspaceAnalysisData.workspaceId}
                    plan={resolved[0] ? resolved[0].plan : null}
                    workspaceOwner={resolved[1].workspaceOwner}
                    lang={currentLang.value}
                  />
                )
              }
              /** **************************************
               * Footer
               *************************************** */
              footerSection={null}
              /** **************************************
               * Style
               *************************************** */
              cssVars={{
                ...navColorVars.layout,
                '--layout-transition-easing': 'linear',
                '--layout-transition-duration': '120ms',
                '--layout-nav-mini-width': '88px',
                '--layout-nav-vertical-width': '300px',
                '--layout-nav-horizontal-height': '64px',
                '--layout-dashboard-content-pt': theme.spacing(1),
                '--layout-dashboard-content-pb': theme.spacing(8),
                '--layout-dashboard-content-px': theme.spacing(5),
              }}
              sx={{
                [`& .${layoutClasses.hasSidebar}`]: {
                  [theme.breakpoints.up(layoutQuery)]: {
                    transition: theme.transitions.create(['padding-left'], {
                      easing: 'var(--layout-transition-easing)',
                      duration: 'var(--layout-transition-duration)',
                    }),
                    pl: isNavMini
                      ? 'var(--layout-nav-mini-width)'
                      : 'var(--layout-nav-vertical-width)',
                  },
                },
                ...sx,
              }}
            >
              <Main sx={{ px: 2, pb: 10 }} isNavHorizontal={isNavHorizontal}>
                {children}
              </Main>
            </LayoutSection>
          </>
        )}
      </Await>
    </Suspense>
  );
}

// ----------------------------------------------------------------------

function useNavColorVars(
  theme: Theme,
  settings: SettingsState
): Record<'layout' | 'section', CSSObject> {
  const {
    vars: { palette },
  } = theme;

  return useMemo(() => {
    switch (settings.navColor) {
      case 'integrate':
        return {
          layout: {
            '--layout-nav-bg': palette.background.default,
            '--layout-nav-horizontal-bg': varAlpha(palette.background.defaultChannel, 0.8),
            '--layout-nav-border-color': varAlpha(palette.grey['500Channel'], 0.12),
            '--layout-nav-text-primary-color': palette.text.primary,
            '--layout-nav-text-secondary-color': palette.text.secondary,
            '--layout-nav-text-disabled-color': palette.text.disabled,
            [stylesMode.dark]: {
              '--layout-nav-border-color': varAlpha(palette.grey['500Channel'], 0.08),
              '--layout-nav-horizontal-bg': varAlpha(palette.background.defaultChannel, 0.96),
            },
          },
          section: {},
        };
      case 'apparent':
        return {
          layout: {
            '--layout-nav-bg': palette.grey[900],
            '--layout-nav-horizontal-bg': varAlpha(palette.grey['900Channel'], 0.96),
            '--layout-nav-border-color': 'transparent',
            '--layout-nav-text-primary-color': palette.common.white,
            '--layout-nav-text-secondary-color': palette.grey[500],
            '--layout-nav-text-disabled-color': palette.grey[600],
            [stylesMode.dark]: {
              '--layout-nav-bg': palette.grey[800],
              '--layout-nav-horizontal-bg': varAlpha(palette.grey['800Channel'], 0.8),
            },
          },
          section: {
            // caption
            '--nav-item-caption-color': palette.grey[600],
            // subheader
            '--nav-subheader-color': palette.grey[600],
            '--nav-subheader-hover-color': palette.common.white,
            // item
            '--nav-item-color': palette.grey[500],
            '--nav-item-root-active-color': palette.primary.light,
            '--nav-item-root-open-color': palette.common.white,
            // bullet
            '--nav-bullet-light-color': bulletColor.dark,
            // sub
            ...(settings.navLayout === 'vertical' && {
              '--nav-item-sub-active-color': palette.common.white,
              '--nav-item-sub-open-color': palette.common.white,
            }),
          },
        };
      default:
        throw new Error(`Invalid color: ${settings.navColor}`);
    }
  }, [
    palette.background.default,
    palette.background.defaultChannel,
    palette.common.white,
    palette.grey,
    palette.primary.light,
    palette.text.disabled,
    palette.text.primary,
    palette.text.secondary,
    settings.navColor,
    settings.navLayout,
  ]);
}
