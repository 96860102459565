import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';

import { getAuth } from 'src/auth/context';

import type { DeleteMemberOutput, InviteMemberOutput } from '../../type';

export const memberListAction = async ({ request }: { request: Request }) => {
  switch (request.method) {
    case 'POST': {
      const data = Object.fromEntries(
        await request.formData()
      ) as Schema['InviteUserResolver']['args'] & { plan: string };

      const res = await inviteUser(data);

      return json(res);
    }
    case 'PATCH': {
      const data = Object.fromEntries(await request.formData()) as {
        memberId: string;
        workspaceOwnerId: string;
        role: Schema['WorkspaceUser']['type']['role'];
        workspaceId: string;
        currentRole: Schema['WorkspaceUser']['type']['role'];
      };

      const res = await changeUserRole(data);

      return json(res);
    }
    case 'DELETE': {
      const data = Object.fromEntries(
        await request.formData()
      ) as Schema['DeleteMemberFromWorkspaceResolver']['args'];

      const res = await deleteUser(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

const inviteUser = async (
  data: Schema['InviteUserResolver']['args'] & { plan: string }
): Promise<ActionResponse<InviteMemberOutput>> => {
  try {
    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        redirectPath: paths.auth.signIn,
      };
    }

    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth() + 1;

    let editor = 0;

    const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);

    if (!thisMonthData) {
      throw new Error('workspace analytics data not found');
    }

    if (data.role !== 'Viewer') {
      if (data.plan === 'Pro' && thisMonthData.editor >= 5) {
        const { editor: _editor } = thisMonthData;

        editor = _editor;

        return {
          userMessage: {
            severity: 'error',
            message: 'Proプランでは、最大5人までしか招待ができません',
          },
        };
      }
    }

    const res = await client.mutations.InviteUserResolver(data);

    // 復旧可能なエラーの場合は、エラーメッセージを表示する
    if (res.data?.message) {
      return {
        userMessage: {
          message: res.data.message,
          severity: 'info',
        },
      };
    }

    if (res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }

    if (!res.data?.success) {
      throw new Error('failed to invite user');
    }

    await client.models.WorkspaceAnalytics.update({
      workspaceId: data.workspaceId,
      year: thisYear,
      month: thisMonth,
      editor: editor + 1,
    });

    return {
      userMessage: {
        severity: 'success',
        message: 'メンバーを招待しました。',
      },
      data: {
        ok: res.data?.success,
      },
    };
  } catch (err) {
    sentryCaptureError(err);

    return {
      userMessage: {
        severity: 'error',
        message: 'メンバーの招待に失敗しました。時間を置いて再度お試しください。',
      },
    };
  }
};

const changeUserRole = async (data: {
  memberId: string;
  workspaceOwnerId: string;
  role: Schema['WorkspaceUser']['type']['role'];
  workspaceId: string;
  currentRole: Schema['WorkspaceUser']['type']['role'];
}): Promise<ActionResponse<void>> => {
  try {
    await client.models.WorkspaceUser.update({
      userId: data.memberId ?? '',
      workspaceId: data.workspaceId,
      role: data.role,
    });

    if (data.role === 'Owner') {
      await client.models.Workspace.update({
        id: data.workspaceId,
        owner: data.memberId ?? '',
      });

      await client.models.WorkspaceUser.update({
        userId: data.workspaceOwnerId,
        workspaceId: data.workspaceId,
        role: 'Admin',
      });
    }

    // 編集者/管理者->閲覧者の場合
    if (data.role === 'Viewer') {
      const thisYear = new Date().getFullYear();
      const thisMonth = new Date().getMonth() + 1;

      const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);
      await client.models.WorkspaceAnalytics.update({
        workspaceId: data.workspaceId,
        year: thisYear,
        month: thisMonth,
        editor: thisMonthData.editor - 1,
      });
    }

    // 閲覧者->編集者/管理者の場合
    if (data.currentRole === 'Viewer') {
      const thisYear = new Date().getFullYear();
      const thisMonth = new Date().getMonth() + 1;

      const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);
      await client.models.WorkspaceAnalytics.update({
        workspaceId: data.workspaceId,
        year: thisYear,
        month: thisMonth,
        editor: thisMonthData.editor + 1,
      });
    }

    return {
      userMessage: {
        severity: 'success',
        message: 'ユーザーの権限を変更しました。',
      },
    };
  } catch (err) {
    sentryCaptureError(err);

    return {
      userMessage: {
        severity: 'error',
        message: 'ユーザーの権限の変更に失敗しました。時間を置いて再度お試しください。',
      },
    };
  }
};

const deleteUser = async (
  data: Schema['DeleteMemberFromWorkspaceResolver']['args']
): Promise<ActionResponse<DeleteMemberOutput>> => {
  try {
    const res = await client.mutations.DeleteMemberFromWorkspaceResolver(data);

    if (res.data?.message) {
      return {
        userMessage: {
          message: res.data.message,
          severity: 'info',
        },
      };
    }

    if (res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }

    return {
      userMessage: {
        severity: 'success',
        message: 'メンバーをワークスペースから削除しました。',
      },
      data: {
        userId: data.userId,
      },
    };
  } catch (err) {
    return {
      userMessage: {
        severity: 'error',
        message: 'メンバーの削除に失敗しました。時間を置いて再度お試しください。',
      },
    };
  }
};
