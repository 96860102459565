import type { ActionResponse } from 'src/types/common';

import * as zod from 'zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFetcher } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import { LoadingButton } from '@mui/lab';
import { Box, Stack, Dialog, Button, DialogTitle } from '@mui/material';

import { useHandleActionData } from 'src/hooks/use-handle-action-data';

import { Iconify } from 'src/components/iconify';
import { Form, Field } from 'src/components/hook-form';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------

type Props = {
  open: boolean;
  onClose: () => void;
  handleCloseCreate: () => void;
};

// ----------------------------------------

export function CreateDialog({ open, onClose, handleCloseCreate }: Props) {
  const { checkUserSession } = useAuthContext();

  const {
    submit,
    data: fetcherData,
    state,
  } = useFetcher<ActionResponse<{ workspaceId: string }>>();

  const defaultValues = {
    WorkspaceName: '',
  };

  const CreateWorkspaceSchema = zod.object({
    WorkspaceName: zod.string().min(1, { message: 'ワークスペース名は必須です。' }),
  });

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(CreateWorkspaceSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = handleSubmit((formData) => {
    const jsonData = JSON.stringify({
      workspaceName: formData.WorkspaceName,
    });
    submit(jsonData, {
      method: 'POST',
      action: '/action/workspace-list',
      encType: 'application/json',
    });
  });

  const loading = state === 'loading' || state === 'submitting';
  const submitted = state === 'idle' && isSubmitSuccessful;

  const handleActionData = useHandleActionData();

  useEffect(() => {
    if (submitted) {
      handleActionData(fetcherData);
      handleCloseCreate();
      reset();

      if (fetcherData?.data) {
        checkUserSession?.();
      }
    }
  }, [fetcherData, handleActionData, submitted, reset, checkUserSession, handleCloseCreate]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle alignContent="center" display="flex">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="solar:tag-horizontal-bold" sx={{ mr: 2 }} />
          ワークスペースを新規作成
        </Stack>
      </DialogTitle>
      <Box sx={{ p: 2 }}>
        <Form methods={methods} onSubmit={onSubmit}>
          <Field.Text
            name="WorkspaceName"
            label="新規ワークスペース名"
            variant="outlined"
            fullWidth
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={onClose}>キャンセル</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ ml: 1 }}
              loading={loading}
              color="primary"
            >
              新規作成
            </LoadingButton>
          </Box>
        </Form>
      </Box>
    </Dialog>
  );
}
