import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';

async function getPlan(workspaceId?: string) {
  try {
    if (!workspaceId) {
      throw new Error('workspaceId is required');
    }

    const query = client.models.Plan.listPlanByWorkspaceId;

    const params = {
      workspaceId,
      filter: { status: { eq: 'Active' } },
    };

    const plans = await fetchAllData<
      Schema['Plan']['type'],
      Schema['Plan']['secondaryIndexes']['listPlanByWorkspaceId']['input']
    >(query, params);

    if (plans.data && plans.data.length > 0) {
      return plans.data[0];
    }
    return null;
  } catch (err) {
    console.error('Error in getPlan:', err);
    return null;
  }
}

async function getWorkspaceOwner(input: { id: Schema['Workspace']['type']['id'] }) {
  const { data } = await client.models.Workspace.get(input);
  return {
    workspaceOwner: data?.owner,
  };
}

const getWorkspaceAnalysis = async (workspaceId?: string) => {
  if (!workspaceId) {
    throw new Error('workspaceId is required');
  }
  const thisMonthData = await getWorkspaceAnalyticsData(workspaceId);
  return { workspaceAnalysisData: thisMonthData };
};

export const planLoader = async (workspaceId?: string) => {
  if (!workspaceId) {
    throw new Error('workspaceId is required');
  }

  const getPlanRes = { plan: await getPlan(workspaceId) };

  const getWorkspaceOwnerRes = await getWorkspaceOwner({ id: workspaceId });

  const getWorkspaceAnalysisRes = await getWorkspaceAnalysis(workspaceId);

  const promisePlan = Promise.all([getPlanRes, getWorkspaceOwnerRes, getWorkspaceAnalysisRes]);

  return defer({ promisePlan });
};
