import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

async function getPlan(workspaceId?: string) {
  try {
    if (!workspaceId) {
      throw new Error('workspaceId is required');
    }

    const query = client.models.Plan.listPlanByWorkspaceId;

    const params = {
      workspaceId,
      filter: { status: { eq: 'Active' } },
    };

    const plans = await fetchAllData<
      Schema['Plan']['type'],
      Schema['Plan']['secondaryIndexes']['listPlanByWorkspaceId']['input']
    >(query, params);

    if (plans.data && plans.data.length > 0) {
      return plans.data[0];
    }

    if (!plans.data || plans.data.length === 0) {
      // NOTE: プランが存在しない場合、新規作成
      const res = await client.models.Plan.create({
        workspaceId,
        status: 'Active',
        plan: 'Free',
        stripeDealId: 'mock',
        startDate: new Date().toISOString(),
        credit: 2200,
        isFirst: true,
      });

      return res.data;
    }

    return null;
  } catch (err) {
    console.error('Error in getPlan:', err);
    return null;
  }
}

export async function getWorkspaceAnalyticsData(workspaceId: string) {
  const dt = new Date();
  const thisYear = dt.getFullYear();
  const thisMonth = dt.getMonth() + 1;
  const query = client.models.WorkspaceAnalytics.listWorkspaceAnalyticsByWorkspaceIdAndMonthAndYear;

  // NOTE: 今月のデータが存在するか確認
  // 例えば2024/10/15にアクセスした時、まずはyear=2024, month=10でデータを取得し、存在すればそれを返す
  const params1 = {
    workspaceId,
    monthYear: { eq: { month: thisMonth, year: thisYear } },
  };

  const thisMonthData = await fetchAllData<
    Schema['WorkspaceAnalytics']['type'],
    Schema['WorkspaceAnalytics']['secondaryIndexes']['listWorkspaceAnalyticsByWorkspaceIdAndMonthAndYear']['input']
  >(query, params1);

  if (thisMonthData.data.length === 1 && new Date(thisMonthData.data[0]?.endDate) >= new Date()) {
    return thisMonthData.data[0];
  }

  // NOTE: 今月のデータが存在しなかった場合、前月のデータを参照
  // データが存在しなかったら、year=2024, month=9でデータを取得し、存在すればそれを返す
  const params2 = {
    workspaceId,
    monthYear: {
      eq: {
        month: thisMonth === 1 ? 12 : thisMonth - 1,
        year: thisMonth === 1 ? thisYear - 1 : thisYear,
      },
    },
  };
  const lastMonthData = await fetchAllData<
    Schema['WorkspaceAnalytics']['type'],
    Schema['WorkspaceAnalytics']['secondaryIndexes']['listWorkspaceAnalyticsByWorkspaceIdAndMonthAndYear']['input']
  >(query, params2);

  if (lastMonthData.data.length === 1 && new Date(lastMonthData.data[0]?.endDate) >= new Date()) {
    return lastMonthData.data[0];
  }

  const plan = await getPlan(workspaceId);

  if (!plan) {
    throw new Error('Failed to get plan');
  }

  // NOTE: それでもデータが存在しなかったら、過去のものを参照して適切なendDateを設定して新規作成
  const previousData = await client.models.WorkspaceAnalytics.list({
    workspaceId,
  });

  // データが一つもない場合、新規作成
  if (!previousData.data || previousData.data.length === 0) {
    const lastEndDate = new Date();
    const newEndDate = new Date(lastEndDate.setMonth(dt.getMonth() + 1));
    const res = await client.models.WorkspaceAnalytics.create({
      endDate: newEndDate.toISOString(),
      workspaceId,
      credit: plan.credit,
      month: thisMonth,
      year: thisYear,
      count: 0,
      editor: 0,
      inputToken: 0,
      outputToken: 0,
      addedCredit: 0,
      currentPlan: plan.plan,
    });

    if (!res.data) {
      throw new Error('Failed to create workspace analytics data');
    }

    return res.data;
  }

  // NOTE: 一つ以上データがある場合、最新のデータを取得
  // year, monthでソートし、最も新しいデータを取得
  const latestData = previousData.data.sort((a, b) => {
    // 年で比較、同じ年なら月で比較
    if (a.year !== b.year) {
      return b.year - a.year;
    }
    return b.month - a.month;
  })[0];

  const lastEndDate = new Date(latestData?.endDate);
  const newEndDate = new Date(
    lastEndDate.getMonth() === 11
      ? lastEndDate.setFullYear(lastEndDate.getFullYear() + 1)
      : lastEndDate.getFullYear(),
    lastEndDate.getMonth() + 1
  );

  // NOTE: クーポンが有効な場合、Proプランに変更
  const couponEndDate = latestData?.couponEndDate ? new Date(latestData.couponEndDate) : null;
  const isCouponValid = couponEndDate && couponEndDate >= new Date();
  const currentPlan = isCouponValid ? 'Pro' : plan.plan;
  const credit = isCouponValid ? 11000 : plan.credit;

  const res = await client.models.WorkspaceAnalytics.create({
    endDate: newEndDate.toISOString(),
    workspaceId,
    credit,
    month: thisMonth,
    year: thisYear,
    currentPlan,
    ...(isCouponValid && { couponEndDate: couponEndDate.toISOString() }),
    count: 0,
    editor: latestData.editor,
    inputToken: 0,
    outputToken: 0,
    addedCredit: latestData.addedCredit,
  });

  if (!res.data) {
    throw new Error('Failed to create workspace analytics data');
  }

  return res.data;
}
