import type { ActionResponse } from 'src/types/common';

import { json } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';

type InputType = {
  businessModelId: string;
  workspaceId: string;
  organizationName: string; // 組織名
  organizationDepartmentName: string; // 部署名
  organizationUrl: string; // 組織の企業ページのURL
  organizationDescription: string; // 組織の説明
  organizationExistingCustomers: string; // 既存の顧客
  organizationValueProvidedToCustomers: string; // 顧客に提供する価値
  organizationOwnedResources: string; // 所有しているリソース
  issue: string; // 課題
  idea: string; // アイデア
  initialCustomer: string; // 初期顧客
  customerNeeds: string; // 顧客のニーズ
  solution: string; // 解決策
  valueProposition: string; // 価値提案
  channels: string; // チャネル
};

export async function businessStatusHistoryAction({ request }: { request: Request }) {
  switch (request.method) {
    case 'POST': {
      const data = (await request.json()) as InputType;

      const res = await createBusinessStatusHistory(data);

      return json(res);
    }
    default: {
      return json({
        userMessage: {
          message: 'ビジネスの詳細の更新に失敗しました',
          severity: 'error',
        },
      });
    }
  }
}

async function createBusinessStatusHistory(data: InputType): Promise<ActionResponse<null>> {
  try {
    const res = await client.models.BusinessStatusHistory.create({
      ...data,
      changed: true,
      createdAt: new Date().toISOString(),
    });

    if (!res.data && !res.errors) {
      return {
        userMessage: {
          message: 'ビジネスの詳細の更新に失敗しました',
          severity: 'error',
        },
      };
    }

    return {
      userMessage: {
        message: 'ビジネスの詳細を更新しました',
        severity: 'success',
      },
    };
  } catch (error) {
    console.error(error);
    return {
      userMessage: {
        message: 'ビジネスの詳細の更新に失敗しました',
        severity: 'error',
      },
    };
  }
}
