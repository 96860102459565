import Box from '@mui/material/Box';
import { alpha, Stack, useTheme, Typography } from '@mui/material';

import { bgGradient } from 'src/theme/styles';

// ----------------------------------------------------------------------

export function Section() {
  const theme = useTheme();
  return (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Stack spacing={2} sx={{ maxWidth: 720 }}>
        <Typography variant="h3">あなたの思い描くビジネスモデルをカタチに。</Typography>
        <Typography variant="body1">
          ビジネスモデルジェネレーターで、AIとビジネスモデルを共創してみませんか？
        </Typography>
      </Stack>
      <Box
        component="img"
        alt="auth"
        src="/assets/illustrations/login.svg"
        sx={{
          maxWidth: {
            xs: '80%',
            lg: 560,
            xl: 720,
          },
        }}
      />
    </Stack>
  );
}
