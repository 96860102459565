import type { RouteObject } from 'react-router-dom';

import { lazy, Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { withAuthCheck } from 'src/utils/with-auth-check';

import { WorkspaceLayout } from 'src/layouts/workspace';
import { BusinessPlanLayout } from 'src/layouts/workspace/business-plan-layout';

import { LoadingScreen } from 'src/components/loading-screen';

import { planLoader } from 'src/sections/workspace/plan/loaders/loader';
import { userPasswordAction } from 'src/sections/workspace/user/actions/password/action';
import { userProfileAction } from 'src/sections/workspace/user/actions/user-profile/action';
import { userProfileLoader } from 'src/sections/workspace/user/loaders/user-profile/loader';
import { memberListAction } from 'src/sections/workspace/members/actions/member-list/action';
import { memberListLoader } from 'src/sections/workspace/members/loaders/member-list/loader';
import { organizationLoader } from 'src/sections/workspace/organization/loader/organization/loader';
import { organizationAction } from 'src/sections/workspace/organization/actions/organization/action';
import { kpiAction } from 'src/sections/workspace/business-models/business-model/actions/kpi/action';
import { workspaceListLoader } from 'src/sections/workspace/workspace-list/loader/workspace-list/loader';
import { workspaceListAction } from 'src/sections/workspace/workspace-list/action/workspace-list/action';
import { personaAction } from 'src/sections/workspace/business-models/business-model/actions/persona/action';
import { journeyAction } from 'src/sections/workspace/business-models/business-model/actions/journey/action';
import { organizationDetailLoader } from 'src/sections/workspace/organization/organization-detail/loader/loader';
import { businessModelListAction } from 'src/sections/workspace/business-models/actions/business-model-list/action';
import { businessModelListLoader } from 'src/sections/workspace/business-models/loaders/business-model-list/loader';
import { businessModelOrganizationAction } from 'src/sections/workspace/business-models/actions/organization/action';
import { AgentUpdateAction } from 'src/sections/workspace/business-models/business-model/actions/agent-update/action';
import { businessModelCanvasAction } from 'src/sections/workspace/business-models/business-model/actions/canvas/action';
import { journeyElementAction } from 'src/sections/workspace/business-models/business-model/actions/journeyElement/action';
import { BusinessModelAction } from 'src/sections/workspace/business-models/business-model/actions/business-model-action/action';
import { businessModelCanvasFieldAction } from 'src/sections/workspace/business-models/business-model/actions/canvas-field/action';
import { businessModelCanvasLoader } from 'src/sections/workspace/business-models/business-model/loaders/business-model-canvas/loader';
import { businessModelStatusAction } from 'src/sections/workspace/business-models/business-model/actions/business-model-status/action';
import { businessStatusHistoryAction } from 'src/sections/workspace/business-models/business-model/actions/business-status-history/action';
import { CommentOnBusinessModelAction } from 'src/sections/workspace/business-models/business-model/actions/comment-on-business-model/action';
import { reviseBusinessModelCanvasFieldAction } from 'src/sections/workspace/business-models/business-model/actions/canvas-field/revise/action';
import { reviseBusinessModelCanvasFieldByUserQueryAction } from 'src/sections/workspace/business-models/business-model/actions/canvas-field/revise-by-user-query/action';

import { AuthGuard } from 'src/auth/guard';

import { headerLoader } from './loaders/loader';

// ----------------------------------------------------------------------

// Index
const IndexPage = lazy(() => import('src/pages/workspace'));

// Invited
const WorkspaceInvited = lazy(() => import('src/pages/workspace/invited'));

// Business Models
const BusinessModelListPage = lazy(() => import('src/pages/workspace/business-models'));
const BusinessModelDetailPage = lazy(
  () => import('src/pages/workspace/business-models/business-model/index')
);

// Member
const MemberListPage = lazy(() => import('src/pages/workspace/members'));

// User
const UserProfilePage = lazy(() => import('src/pages/workspace/user'));

// Organization
const OrganizationPage = lazy(() => import('src/pages/workspace/organization'));
const OrganizationDetailView = lazy(() => import('src/pages/workspace/organization/detail'));

// Agent
const AgentListPage = lazy(() => import('src/pages/workspace/agent'));

// Plan
const PlanPage = lazy(() => import('src/pages/workspace/plan'));
const BusinessPlanApply = lazy(() => import('src/pages/workspace/plan/form'));

const Page500 = lazy(() => import('src/pages/error/500'));

// Workspace List
const WorkspaceListPage = lazy(() => import('src/pages/workspace/workspace-list'));

// ----------------------------------------------------------------------

const LayoutWrapper = ({ pathname }: { pathname: string }) => {
  // workspaceLayoutを使わないルート
  const excludeWorkspaceLayoutPaths = ['/plan/form'];
  const location = useLocation();

  const isExcludedWorkspaceLayoutPaths = excludeWorkspaceLayoutPaths.some((path) =>
    location.pathname.endsWith(path)
  );

  if (isExcludedWorkspaceLayoutPaths) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    );
  }

  return (
    <WorkspaceLayout>
      <Outlet />
    </WorkspaceLayout>
  );
};

export const dashboardRoutes: RouteObject[] = [
  {
    path: ':workspaceId',
    element: (
      <AuthGuard>
        <LayoutWrapper pathname={window.location.pathname} />
      </AuthGuard>
    ),
    loader: withAuthCheck(({ params }) => headerLoader(params.workspaceId)),
    errorElement: <Page500 />,
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'invited',
        element: <WorkspaceInvited />,
      },
      {
        path: 'business-models',
        children: [
          {
            index: true,
            element: <BusinessModelListPage />,
            loader: withAuthCheck(({ params }) => businessModelListLoader(params.workspaceId)),
            action: businessModelListAction,
          },
          {
            path: 'organization-action',
            action: businessModelOrganizationAction,
          },
          {
            path: 'business-status-history-action',
            action: businessStatusHistoryAction,
          },
          {
            path: ':businessModelId/:businessModelHistoryId',
            element: <BusinessModelDetailPage />,
            loader: withAuthCheck(({ params }) =>
              businessModelCanvasLoader(
                params.businessModelId,
                params.businessModelHistoryId,
                params.workspaceId
              )
            ),
            children: [
              {
                path: 'canvas',
                action: businessModelCanvasAction,
              },
              {
                path: 'canvas-field',
                action: businessModelCanvasFieldAction,
              },
              {
                path: 'revise-canvas-field',
                action: reviseBusinessModelCanvasFieldAction,
              },
              {
                path: 'revise-canvas-field-by-user-query',
                action: reviseBusinessModelCanvasFieldByUserQueryAction,
              },
              {
                path: 'agent-update',
                action: AgentUpdateAction,
              },
              {
                path: 'comment-on-business-model',
                action: CommentOnBusinessModelAction,
              },
              {
                path: 'business-model-action',
                action: BusinessModelAction,
              },
              {
                path: 'kpi',
                action: kpiAction,
              },
              {
                path: 'businessModelStatus',
                action: businessModelStatusAction,
              },
              {
                path: 'persona',
                action: personaAction,
              },
              {
                path: 'journey',
                action: journeyAction,
              },
              {
                path: 'journeyElement',
                action: journeyElementAction,
              },
            ],
          },
        ],
      },
      {
        path: 'members',
        element: <MemberListPage />,
        loader: withAuthCheck(({ params }) => memberListLoader(params.workspaceId)),
        action: memberListAction,
      },
      {
        path: 'user',
        loader: withAuthCheck(userProfileLoader),
        element: <UserProfilePage />,
        children: [
          {
            path: 'profile',
            action: userProfileAction,
          },
          {
            path: 'password',
            action: userPasswordAction,
          },
        ],
      },
      {
        path: 'organization',
        children: [
          {
            index: true,
            element: <OrganizationPage />,
            action: organizationAction,
            loader: withAuthCheck(({ params }) => organizationLoader(params.workspaceId)),
          },
          {
            path: ':organizationId',
            element: <OrganizationDetailView />,
            loader: withAuthCheck(({ params }) => organizationDetailLoader(params.organizationId)),
            action: organizationAction,
          },
        ],
      },
      {
        path: 'agents',
        loader: withAuthCheck(({ params }) => memberListLoader(params.workspaceId)),
        element: <AgentListPage />,
      },
      {
        path: 'plan',
        loader: withAuthCheck(({ params }) => planLoader(params.workspaceId)),
        element: <PlanPage />,
      },
      {
        path: 'plan/form',
        element: (
          <BusinessPlanLayout>
            <BusinessPlanApply />
          </BusinessPlanLayout>
        ),
      },
      {
        path: 'workspace-list',
        element: <WorkspaceListPage />,
        loader: withAuthCheck(workspaceListLoader),
        action: workspaceListAction,
      },
    ],
  },
];
