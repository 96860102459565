import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { fetchAuthSession } from 'aws-amplify/auth';

import { client } from 'src/utils/amplify-client-utils';

export async function deleteWorkspace(
  input: Schema['Workspace']['updateType']
): Promise<ActionResponse<Schema['Workspace']['updateType']>> {
  try {
    const res = await client.models.Workspace.update(input);

    return {
      data: res.data,
      userMessage: {
        message: 'ワークスペースの削除に成功しました。',
        severity: 'success',
      },
    };
  } catch (err) {
    return {
      userMessage: {
        message: 'ワークスペースの削除に失敗しました。',
        severity: 'error',
      },
    };
  }
}

export async function updateWorkspace(
  input: Schema['Workspace']['updateType']
): Promise<ActionResponse<Schema['Workspace']['updateType']>> {
  try {
    const res = await client.models.Workspace.update(input);

    if (!res.data) {
      return {
        userMessage: {
          message: 'ワークスペース名の更新に失敗しました。',
          severity: 'error',
        },
      };
    }

    return {
      data: res.data,
      userMessage: {
        message: 'ワークスペース名の更新に成功しました。',
        severity: 'success',
      },
    };
  } catch (err) {
    console.error(err);
    return {
      userMessage: {
        message: 'ワークスペース名の更新に失敗しました。',
        severity: 'error',
      },
    };
  }
}

export async function createWorkspace(
  input: Schema['MultiWorkspaceResolver']['args']
): Promise<ActionResponse<string>> {
  try {
    const res = await client.mutations.MultiWorkspaceResolver(input);

    if (!res.data || !res.data.workspaceId) {
      throw new Error('Failed to create workspace');
    }

    const dt = new Date();
    const thisYear = dt.getFullYear();
    const thisMonth = dt.getMonth() + 1;

    const workspaceAnalysisRes = await client.models.WorkspaceAnalytics.create({
      endDate: new Date(dt.setMonth(dt.getMonth() + 1)).toISOString(),
      workspaceId: res.data.workspaceId,
      credit: 2200,
      month: thisMonth,
      year: thisYear,
      count: 0,
      editor: 0,
      inputToken: 0,
      outputToken: 0,
      addedCredit: 0,
    });

    await fetchAuthSession({ forceRefresh: true });

    const planRes = await client.models.Plan.create({
      plan: 'Free',
      startDate: dt.toISOString(),
      workspaceId: res.data.workspaceId,
      stripeDealId: 'mock',
      status: 'Active',
      credit: 2200,
      isFirst: true,
    });

    if (!planRes.data) {
      throw new Error(`Failed to create plan1: ${planRes.errors}`);
    }

    if (!workspaceAnalysisRes.data) {
      throw new Error(`Failed to create workspace analytics: ${workspaceAnalysisRes.errors}`);
    }

    return {
      data: res.data?.workspaceId,
      userMessage: {
        message: 'ワークスペースの作成に成功しました。',
        severity: 'success',
      },
    };
  } catch (err) {
    console.error(err);
    return {
      userMessage: {
        message: 'ワークスペースの作成に失敗しました。',
        severity: 'error',
      },
    };
  }
}
