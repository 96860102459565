import type { ActionResponse } from 'src/types/common';

import { json } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';

import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';
import { updateCredit, checkThisMonthDataAndCredit } from 'src/actions/plan';
import { generateJourneyElementCreditConsume } from 'src/assets/data/credit';

import { getAuth } from 'src/auth/context';

import type { BusinessModelCanvasType } from '../../type';

// ----------------------------------------------------------------------------

type GenerateJourneyElementInput = {
  workspaceId: string;
  businessModelId: string;
  personas: string;
  journeyActions: string;
  businessModelCanvas: BusinessModelCanvasType;
};

// ----------------------------------------------------------------------------

export async function journeyElementAction({ request }: { request: Request }) {
  switch (request.method) {
    case 'POST': {
      const data = (await request.json()) as GenerateJourneyElementInput;

      const res = await generateBusinessModelJourneyElement(data);

      return json(res);
    }

    default: {
      return null;
    }
  }
}

// ----------------------------------------------------------------------------

async function generateBusinessModelJourneyElement(
  data: GenerateJourneyElementInput
): Promise<ActionResponse<null>> {
  try {
    const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);

    checkThisMonthDataAndCredit(thisMonthData, generateJourneyElementCreditConsume);

    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    const { businessModelCanvas, ...rest } = data;

    const generateJourneyElementRes = await axios.post(
      'journey-element',
      {
        ...rest,
        ...businessModelCanvas,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.idToken.toString()}`,
        },
      }
    );

    console.log({ generateJourneyElementRes });

    await Promise.all(
      generateJourneyElementRes?.data?.data?.journeyElements.map((element: any) =>
        element.journeyElement.map((element2: any) =>
          client.models.JourneyActionElement.create({
            ...element2,
            workspaceId: data.workspaceId,
            businessModelId: data.businessModelId,
            createdAt: new Date().toISOString(),
          })
        )
      )
    );

    const inputTokens = generateJourneyElementRes.data?.inputTokens || 0;
    const outputTokens = generateJourneyElementRes.data?.outputTokens || 0;

    await updateCredit(
      generateJourneyElementCreditConsume,
      thisMonthData,
      data.workspaceId,
      inputTokens,
      outputTokens
    );

    return {
      userMessage: {
        message: 'カスタマージャーニーを生成しました。',
        severity: 'success',
      },
    };
  } catch (error) {
    return {
      userMessage: {
        message: 'カスタマージャーニーの生成に失敗しました。',
        severity: 'error',
      },
    };
  }
}
