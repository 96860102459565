import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json } from 'react-router-dom';

import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

type InputType = {
  businessModelHistoryId: string;
  status: Schema['BusinessModelStatus']['type'];
};

export async function businessModelStatusAction({ request }: { request: Request }) {
  const data = (await request.json()) as InputType;

  switch (request.method) {
    case 'PUT': {
      const res = await updateBusinessModelStatus(data);
      return json(res);
    }
    default: {
      return null;
    }
  }
}

async function updateBusinessModelStatus(
  data: InputType
): Promise<ActionResponse<{ success: boolean }>> {
  try {
    await client.models.BusinessModelHistory.update({
      id: data.businessModelHistoryId,
      status: data.status,
    });

    return {
      userMessage: {
        message: 'ビジネスモデルのステータスを更新しました。',
        severity: 'success',
      },
      data: {
        success: true,
      },
    };
  } catch (error) {
    sentryCaptureError(error);
    return {
      userMessage: {
        message: 'ビジネスモデルのステータスの更新に失敗しました。',
        severity: 'error',
      },
      data: {
        success: false,
      },
    };
  }
}
