import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer, redirect } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';

import { getAuth } from 'src/auth/context';

async function getPlan(workspaceId?: string) {
  try {
    if (!workspaceId) {
      throw new Error('workspaceId is required');
    }

    const query = client.models.Plan.listPlanByWorkspaceId;

    const params = {
      workspaceId,
      filter: { status: { eq: 'Active' } },
    };

    const plans = await fetchAllData<
      Schema['Plan']['type'],
      Schema['Plan']['secondaryIndexes']['listPlanByWorkspaceId']['input']
    >(query, params);

    if (plans?.data && plans?.data?.length > 0) {
      return plans.data[0];
    }

    const dt = new Date();

    const workspaceAnalysisRes = await client.models.WorkspaceAnalytics.create({
      endDate: new Date(dt.setMonth(dt.getMonth() + 1)).toISOString(),
      workspaceId,
      credit: 2200,
      month: dt.getMonth() + 1,
      year: dt.getFullYear(),
      count: 0,
      editor: 0,
      inputToken: 0,
      outputToken: 0,
      addedCredit: 0,
    });

    if (!workspaceAnalysisRes.data) {
      throw new Error(`Failed to create workspace analytics: ${workspaceAnalysisRes.errors}`);
    }

    return null;
  } catch (err) {
    console.error('Error in getPlan:', err);
    return null;
  }
}

async function getWorkspaceOwner(input: { id: Schema['Workspace']['type']['id'] }) {
  const { data } = await client.models.Workspace.get(input);
  return {
    workspaceOwner: data?.owner,
  };
}

async function getWorkspaceList(id: string) {
  await fetchAuthSession({ forceRefresh: true });
  const query = client.models.Workspace.list;
  const params = {
    filter: { userIds: { contains: id }, isDeleted: { eq: false } },
  };

  const workspaceList = await fetchAllData<
    Schema['Workspace']['type'],
    { filter: { userIds: { contains: string }; isDeleted: { eq: boolean } } }
  >(query, params);

  const workspaceIds = workspaceList?.data?.map((workspace) => workspace?.id);

  const workspaceUsersList = await Promise.all(
    workspaceIds?.map((workspaceId) =>
      client.queries.ListCognitoUsersByWorkspaceIdResolver({ workspaceId })
    )
  );

  return {
    workspaceList: workspaceList?.data,
    workspaceUsersList: workspaceUsersList?.map((workspaceUsers: any) => workspaceUsers?.data),
  };
}

const getWorkspaceAnalysis = async (workspaceId?: string) => {
  if (!workspaceId) {
    throw new Error('workspaceId is required');
  }
  const thisMonthData = await getWorkspaceAnalyticsData(workspaceId);

  return { workspaceAnalysisData: thisMonthData };
};

export const headerLoader = async (workspaceId?: string) => {
  if (!workspaceId) {
    return defer({ promisePlan: Promise.resolve(null) });
  }

  const user = await getAuth();

  if (!user.sub) {
    return redirect(paths.auth.signIn);
  }

  const workspaceIdsAttribute =
    ((user as any)?.['custom:workspaceIds'] as string[] | undefined) ?? [];

  // NOTE: workspaceIdsAttributeにworkspaceIdが含まれていない場合は、リダイレクト
  if (!workspaceIdsAttribute?.includes(workspaceId)) {
    return redirect(paths.auth.accessDenied);
  }

  const getPlanRes = { plan: await getPlan(workspaceId) };
  const getWorkspaceOwnerRes = await getWorkspaceOwner({ id: workspaceId });
  const workspaceAnalysisData = await getWorkspaceAnalysis(workspaceId);
  const promiseWorkspaceList = await getWorkspaceList(user.sub);

  const promisePlan = Promise.all([
    getPlanRes,
    getWorkspaceOwnerRes,
    workspaceAnalysisData,
    { workspaceList: promiseWorkspaceList.workspaceList },
    { workspaceUsersList: promiseWorkspaceList.workspaceUsersList },
  ]);

  return defer({ promisePlan });
};
