import type { ActionResponse } from 'src/types/common';

import { json } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';

import { generateJourneyCreditConsume } from 'src/assets/data/credit';
import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';
import { updateCredit, checkThisMonthDataAndCredit } from 'src/actions/plan';

import { getAuth } from 'src/auth/context';

import type { BusinessModelCanvasType } from '../../type';

// ----------------------------------------------------------------------------

type GenerateJourneyInput = {
  workspaceId: string;
  businessModelId: string;
  personas: string;
  businessModelCanvas: BusinessModelCanvasType;
  llmModel: string;
  currentLangCountryCode: string;
};

// ----------------------------------------------------------------------------

export async function journeyAction({ request }: { request: Request }) {
  switch (request.method) {
    case 'POST': {
      const data = (await request.json()) as GenerateJourneyInput;

      const res = await generateBusinessModelJourney(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
}

// ----------------------------------------------------------------------------

async function generateBusinessModelJourney(
  data: GenerateJourneyInput
): Promise<ActionResponse<{ journeyActions: string }>> {
  try {
    const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);

    checkThisMonthDataAndCredit(thisMonthData, generateJourneyCreditConsume);

    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    const { businessModelCanvas, ...rest } = data;

    const generateJourneyRes = await axios.post(
      'journey',
      {
        ...rest,
        ...businessModelCanvas,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.idToken.toString()}`,
        },
      }
    );

    const res = await Promise.all(
      generateJourneyRes?.data?.data?.journeyActions?.map(async (_journeyAction: any) =>
        client.models.JourneyAction.create({
          ..._journeyAction,
          workspaceId: data.workspaceId,
          businessModelId: data.businessModelId,
          createdAt: new Date().toISOString(),
        })
      )
    );

    const inputTokens = generateJourneyRes.data?.inputTokens || 0;
    const outputTokens = generateJourneyRes.data?.outputTokens || 0;

    await updateCredit(
      generateJourneyCreditConsume,
      thisMonthData,
      data.workspaceId,
      inputTokens,
      outputTokens
    );

    return {
      userMessage: {
        message: 'カスタマージャーニーのアクションを生成しました',
        severity: 'success',
      },
      data: {
        journeyActions: JSON.stringify(res),
      },
    };
  } catch (error) {
    console.error(error);

    return {
      userMessage: {
        message: 'カスタマージャーニーのアクションの生成に失敗しました',
        severity: 'error',
      },
    };
  }
}
