import type { NavSectionProps } from 'src/components/nav-section';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTranslate } from 'src/locales';

import { Logo } from 'src/components/logo';

import { HeaderSection } from './header-section';
import { Searchbar } from '../components/searchbar';
import { MenuButton } from '../components/menu-button';
import AccountPopover from '../components/account-popover';
import { SignInButton } from '../components/sign-in-button';
import { ModelSelector } from '../components/model-selector';
import { LanguagePopover } from '../components/language-popover';
import { ContactsPopover } from '../components/contacts-popover';
import WorkspaceSelector from '../components/workspace-selector';
import { NotificationsDrawer } from '../components/notifications-drawer';
import CreditNotificationTooltip from '../components/credit-notification-tooltip';

import type { HeaderSectionProps } from './header-section';
import type { ContactsPopoverProps } from '../components/contacts-popover';
import type { LanguagePopoverProps } from '../components/language-popover';
import type { WorkspacesPopoverProps } from '../components/workspaces-popover';
import type { NotificationsDrawerProps } from '../components/notifications-drawer';

// ----------------------------------------------------------------------

const StyledDivider = styled('span')(({ theme }) => ({
  width: 1,
  height: 10,
  flexShrink: 0,
  display: 'none',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  marginLeft: theme.spacing(2.5),
  marginRight: theme.spacing(2.5),
  backgroundColor: 'currentColor',
  color: theme.vars.palette.divider,
  '&::before, &::after': {
    top: -5,
    width: 3,
    height: 3,
    content: '""',
    flexShrink: 0,
    borderRadius: '50%',
    position: 'absolute',
    backgroundColor: 'currentColor',
  },
  '&::after': { bottom: -5, top: 'auto' },
}));

// ----------------------------------------------------------------------

export type HeaderBaseProps = HeaderSectionProps & {
  onOpenNav: () => void;

  data?: {
    nav?: NavSectionProps['data'];
    account?: {
      displayName: string;
      picture?: string;
      email: string;
    };
    langs?: LanguagePopoverProps['data'];
    contacts?: ContactsPopoverProps['data'];
    workspaces?: WorkspacesPopoverProps['data'];
    notifications?: NotificationsDrawerProps['data'];
    plan?: string;
    workspaceOwner?: string;
    credits?: number;
  };
  slots?: {
    navMobile?: {
      topArea?: React.ReactNode;
      bottomArea?: React.ReactNode;
    };
  };
  slotsDisplay?: {
    signIn?: boolean;
    account?: boolean;
    contacts?: boolean;
    searchbar?: boolean;
    workspaces?: boolean;
    menuButton?: boolean;
    localization?: boolean;
    notifications?: boolean;
  };
  creditAlertOpen?: boolean;
  handleCreditAlertClose?: () => void;
  workspaceList?: Schema['Workspace']['type'][];
  workspaceUsersList?: Schema['ListCognitoUsersByWorkspaceIdResolver']['type'][];
};

export function HeaderBase({
  sx,
  data,
  slots,
  slotProps,
  onOpenNav,
  layoutQuery,
  slotsDisplay: {
    signIn = true,
    account = true,
    contacts = true,
    searchbar = true,
    workspaces = true,
    menuButton = true,
    localization = true,
    notifications = true,
  } = {},
  creditAlertOpen,
  handleCreditAlertClose,
  workspaceList,
  workspaceUsersList,
  ...other
}: HeaderBaseProps) {
  const theme = useTheme();
  const { currentLang } = useTranslate();

  const isFreePlan = !data?.plan ? true : data?.plan === 'Free';

  return (
    <HeaderSection
      sx={sx}
      layoutQuery={layoutQuery}
      credits={data?.credits}
      slots={{
        ...slots,
        leftAreaStart: slots?.leftAreaStart,
        leftArea: (
          <>
            {slots?.leftAreaStart}

            {/* -- Menu button -- */}
            {menuButton && (
              <MenuButton
                data-slot="menu-button"
                onClick={onOpenNav}
                sx={{ mr: 1, ml: -1, [theme.breakpoints.up(layoutQuery)]: { display: 'none' } }}
              />
            )}

            {/* -- Logo -- */}
            <Logo data-slot="logo" />

            {/* -- Divider -- */}
            <StyledDivider data-slot="divider" />

            {/* -- Workspace popover -- */}
            {workspaces && data?.workspaces && (
              <WorkspaceSelector
                workspaceList={workspaceList}
                workspaceUsersList={workspaceUsersList}
              />
            )}

            {workspaces && data?.workspaces && <ModelSelector isFreePlan={isFreePlan} />}

            {slots?.leftAreaEnd}
          </>
        ),

        rightArea: (
          <>
            {slots?.rightAreaStart}

            <Box
              data-area="right"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: 1, sm: 1.5 },
              }}
            >
              <CreditNotificationTooltip
                credits={data?.credits}
                open={creditAlertOpen}
                handleClose={handleCreditAlertClose}
              />

              <Box
                sx={{
                  bgcolor: 'rgba(89, 0, 255, 0.08)',
                  p: 1,
                  borderRadius: '8px',
                }}
              >
                {account && (
                  <Typography variant="button" sx={{ color: '#3B00B2' }}>
                    {currentLang.countryCode === 'JP'
                      ? `${data?.plan ?? 'Free'}プラン`
                      : `${data?.plan ?? 'Free'} Plan`}
                  </Typography>
                )}
              </Box>
              {/* -- Searchbar -- */}
              {searchbar && <Searchbar data-slot="searchbar" data={data?.nav} />}
              {/* -- Language popover -- */}
              {localization && <LanguagePopover data-slot="localization" data={data?.langs} />}
              {/* -- Notifications popover -- */}
              {notifications && (
                <NotificationsDrawer data-slot="notifications" data={data?.notifications} />
              )}
              {/* -- Contacts popover -- */}
              {contacts && <ContactsPopover data-slot="contacts" data={data?.contacts} />}
              {/* -- Account drawer -- */}
              {account && data?.account && (
                <AccountPopover
                  data={{
                    ...data?.account,
                    plan: data?.plan,
                    workspaceOwner: data?.workspaceOwner,
                  }}
                />
              )}
              {/* -- Sign in button -- */}
              {signIn && <SignInButton />}
            </Box>
            {slots?.rightAreaEnd}
          </>
        ),
      }}
      slotProps={slotProps}
      {...other}
    />
  );
}
