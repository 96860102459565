import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';

import { generatePersonaCreditConsume } from 'src/assets/data/credit';
import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';
import { updateCredit, checkThisMonthDataAndCredit } from 'src/actions/plan';

import { getAuth } from 'src/auth/context';

import type { BusinessModelCanvasType } from '../../type';

// ----------------------------------------------------------------------------

type GeneratePersonaInput = {
  workspaceId: string;
  businessModelId: string;
  businessModelCanvas: BusinessModelCanvasType;
  llmModel: string;
  currentLangCountryCode: string;
};

// ----------------------------------------------------------------------------

export async function personaAction({ request }: { request: Request }) {
  switch (request.method) {
    case 'POST': {
      const data = (await request.json()) as GeneratePersonaInput;

      const res = await generateBusinessModelPersona(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
}

// ----------------------------------------------------------------------------

async function generateBusinessModelPersona(
  data: GeneratePersonaInput
): Promise<ActionResponse<Schema['Persona']['type']>> {
  try {
    const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);

    checkThisMonthDataAndCredit(thisMonthData, generatePersonaCreditConsume);

    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    const { businessModelCanvas, ...rest } = data;

    const generatePersonaRes = await axios.post(
      'persona',
      {
        ...rest,
        ...businessModelCanvas,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.idToken.toString()}`,
        },
      }
    );

    await Promise.all(
      generatePersonaRes?.data?.data?.personas?.map(async (persona: any) =>
        client.models.Persona.create({
          ...persona,
          workspaceId: data.workspaceId,
          businessModelId: data.businessModelId,
          createdAt: new Date().toISOString(),
        })
      )
    );

    const inputTokens = generatePersonaRes.data?.inputTokens || 0;
    const outputTokens = generatePersonaRes.data?.outputTokens || 0;

    await updateCredit(
      generatePersonaCreditConsume,
      thisMonthData,
      data.workspaceId,
      inputTokens,
      outputTokens
    );

    return {
      userMessage: {
        message: 'ペルソナを生成しました',
        severity: 'success',
      },
    };
  } catch (error) {
    console.error(error);

    return {
      userMessage: {
        message: 'ペルソナの生成に失敗しました',
        severity: 'error',
      },
    };
  }
}
