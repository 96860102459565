import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';
import type { WorkspaceData } from 'src/sections/workspace/workspace-list/workspace-list-card';

import { useLocation } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';

import { Menu, Divider, MenuItem, Skeleton, ButtonBase, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { Iconify } from 'src/components/iconify';

import { CreateDialog } from 'src/sections/workspace/workspace-list/create-dialog';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

type Props = {
  workspaceList?: Schema['Workspace']['type'][];
  workspaceUsersList?: Schema['ListCognitoUsersByWorkspaceIdResolver']['type'][];
};

export default function WorkspaceSelector({ workspaceList, workspaceUsersList }: Props) {
  const params = useParams();
  const router = useRouter();
  const location = useLocation();

  const mdUp = useResponsive('up', 'md');

  const currentWorkspaceId = params.workspaceId as string;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // メニューを開く
  const filterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // メニューを閉じる
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user } = useAuthContext();

  const isOpen = useBoolean();

  const [isLoading, setIsLoading] = useState(!!sessionStorage.getItem('isLoading'));

  const formattedWorkspaces = useMemo(() => {
    if (!workspaceList || !user) return [];

    return workspaceList.map(
      (workspace): WorkspaceData => ({
        id: workspace.id,
        isDeleted: workspace.isDeleted,
        isInitialWorkspace: workspace.isInitialWorkspace,
        name: workspace.name,
        owner: workspace.owner,
        userIds: (workspace.userIds || []).filter((id): id is string => id !== null),
      })
    );
  }, [workspaceList, user]);

  const sortedWorkspaces = useMemo(
    () =>
      [...formattedWorkspaces].sort((a, b) => {
        const compareResult = a.name.localeCompare(b.name);
        return compareResult;
      }),
    [formattedWorkspaces]
  );

  const currentWorkspace = workspaceList?.find((workspace) => workspace.id === currentWorkspaceId);

  useEffect(() => {
    sessionStorage.removeItem('isLoading');
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rounded" width={200} height={40} />
      ) : (
        <ButtonBase
          onClick={filterClick}
          sx={{
            pl: 0.5,
            py: 0.5,
            pr: 0.5,
            borderRadius: 1,
            bgcolor: 'background.paper',
            maxWidth: 300,
          }}
        >
          <Iconify
            width={22}
            height={22}
            icon="solar:tag-horizontal-bold"
            color="text.secondary"
            sx={{ mr: 1 }}
          />

          {mdUp && (
            <Typography variant="subtitle2" noWrap color="text.secondary">
              {currentWorkspace ? currentWorkspace.name : <Skeleton variant="text" width={100} />}
            </Typography>
          )}
          <Iconify
            width={16}
            icon={anchorEl ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            sx={{ ml: 0.5 }}
            color="text.secondary"
          />
        </ButtonBase>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ width: '300px' }}
      >
        {workspaceUsersList &&
          sortedWorkspaces.map((item, index) => (
            <MenuItem
              key={index}
              disabled={item.id === currentWorkspaceId}
              selected={item.id === currentWorkspaceId}
              onClick={async () => {
                const path = paths.workspace.businessModel(item.id || '');
                sessionStorage.setItem('isLoading', 'true');
                setIsLoading(true);
                handleClose();
                router.push(path);
              }}
            >
              <Iconify
                width={22}
                height={22}
                icon="solar:tag-horizontal-bold"
                color="text.secondary"
              />
              <Typography variant="subtitle2" noWrap color="text.secondary">
                {item.name}
              </Typography>
            </MenuItem>
          ))}

        <Divider
          sx={{ borderStyle: 'dashed' }}
          style={{
            margin: 3,
          }}
        />

        <MenuItem
          onClick={() => {
            isOpen.onTrue();
            handleClose();
          }}
        >
          <Iconify width={22} height={22} icon="mdi:plus" color="text.secondary" />
          <Typography variant="subtitle2" noWrap color="text.secondary">
            新規作成
          </Typography>
        </MenuItem>
      </Menu>

      <CreateDialog
        open={isOpen.value}
        onClose={isOpen.onFalse}
        handleCloseCreate={isOpen.onFalse}
      />
    </>
  );
}
