import type { ActionResponse } from 'src/types/common';
import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { json, type Params } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { fetchAllData } from 'src/utils/fetch-all-data';
import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';

import { getAuth } from 'src/auth/context';

import type { UpdateBusinessModelFieldInput, BusinessModelFieldQueryParams } from '../../type';

export const businessModelCanvasFieldAction = async ({
  request,
}: {
  params: Params<string>;
  request: Request;
}) => {
  switch (request.method) {
    case 'PUT': {
      const data = Object.fromEntries(await request.formData()) as UpdateBusinessModelFieldInput;

      const res = await updateBusinessModelField(data);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function updateBusinessModelField(
  data: UpdateBusinessModelFieldInput
): Promise<ActionResponse<null>> {
  try {
    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    if (!auth?.sub) {
      throw new Error('User ID is undefined');
    }

    const businessModelHistoryData = await client.models.BusinessModelHistory.get({
      id: data.businessModelHistoryId,
    });

    if (!businessModelHistoryData.data) {
      throw new Error('Failed to get business model history');
    }

    const createHistoryRes = await client.models.BusinessModelHistory.create({
      businessModelId: data.businessModelId,
      workspaceId: data.workspaceId,
      owner: auth.sub,
      idea: businessModelHistoryData.data.idea,
      title: businessModelHistoryData.data.title,
      version: businessModelHistoryData.data.version + 1,
      sourceType: 'USER',
      createdAt: new Date().toISOString(),
      status:
        businessModelHistoryData.data.status ??
        ('UnderConsideration' as Schema['BusinessModelStatus']['type']),
    });

    if (!createHistoryRes.data) {
      throw new Error('Failed to create business model history');
    }

    const query = client.models.BusinessModelField.list;

    const params = {
      filter: {
        businessModelHistoryId: { eq: data.businessModelHistoryId },
      },
    };

    const businessModelFieldData = await fetchAllData<
      Schema['BusinessModelField']['type'],
      BusinessModelFieldQueryParams
    >(query, params);

    if (businessModelFieldData.data.length !== 9) {
      throw new Error('Failed to get business model field');
    }

    const newBusinessModelHistoryId = createHistoryRes.data.id;

    const targetField = businessModelFieldData.data.find(
      (field) => field.field === data.editFieldName
    );

    if (!targetField) {
      throw new Error('Target field not found');
    }

    const _query =
      data.lang === 'JP'
        ? `
    以下のキャンバスを比較して、変更内容を一文で要約してください。
    ## 変更前のビジネスモデルキャンバス:
    {${targetField.value}}

    ## 変更後のビジネスモデルキャンバス:
    {${data.editValue}}`
        : `
    Compare the following canvases and summarize the changes in one sentence.
    ## Previous Business Model Canvas:
    {${targetField.value}}

    ## After Business Model Canvas:
    {${data.editValue}}`;

    const changedSummary = await client.mutations.GenerateSummarizationResolver({
      query: _query,
      lang: data.lang,
      llmModel: data.llmModel,
    });

    businessModelFieldData.data.forEach(async (field) => {
      if (field.field === data.editFieldName) {
        await client.models.BusinessModelField.create({
          businessModelHistoryId: newBusinessModelHistoryId,
          changed: true,
          field: field.field,
          value: data.editValue,
          workspaceId: data.workspaceId,
          changedSummary: changedSummary.data?.message,
        });
      } else {
        await client.models.BusinessModelField.create({
          businessModelHistoryId: newBusinessModelHistoryId,
          changed: false,
          field: field.field,
          value: field.value,
          workspaceId: data.workspaceId,
        });
      }
    });

    await client.models.BusinessModelHistory.update({
      id: newBusinessModelHistoryId,
      summarization: changedSummary.data?.message,
    });

    return {
      userMessage: {
        message: 'ビジネスモデルフィールドを更新しました。',
        severity: 'success',
      },
      redirectPath: paths.workspace.edit(
        data.workspaceId,
        data.businessModelId,
        newBusinessModelHistoryId
      ),
    };
  } catch (err) {
    console.error(err);

    sentryCaptureError(err);

    return {
      userMessage: {
        message:
          '申し訳ございませんが、ただいま混雑しております。しばらく時間をおいて実行してください。',
        severity: 'error',
      },
    };
  }
}
