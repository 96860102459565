import type { ActionResponse } from 'src/types/common';

import { json } from 'react-router-dom';
import { type Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';
import { client } from 'src/utils/amplify-client-utils';
import { sentryCaptureError } from 'src/utils/sentry-capture-error';
import { convertLlmOutputToMarkdownList } from 'src/utils/convertLlmOutputToMarkdownList';

import { deleteBusinessModel } from 'src/actions/business-model';
import { generateCanvasCreditConsume } from 'src/assets/data/credit';
import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';
import { updateCredit, checkThisMonthDataAndCredit } from 'src/actions/plan';

import { getAuth } from 'src/auth/context';

import type { GenerateBusinessModelInput } from '../../type';

enum BusinessModelFieldName {
  CustomerSegments = 'customerSegments',
  ValueProposition = 'valueProposition',
  Channels = 'channels',
  CustomerRelationships = 'customerRelationships',
  RevenueStreams = 'revenueStreams',
  KeyResources = 'keyResources',
  KeyActivities = 'keyActivities',
  KeyPartners = 'keyPartners',
  CostStructure = 'costStructure',
}

export const businessModelListAction = async ({ request }: { request: Request }) => {
  switch (request.method) {
    case 'POST': {
      const data = Object.fromEntries(await request.formData()) as GenerateBusinessModelInput;
      const res = await generateBusinessModel(data);

      return json(res);
    }
    case 'DELETE': {
      const data = Object.fromEntries(await request.formData()) as {
        id: Schema['BusinessModel']['type']['id'];
      };

      const res = await deleteBusinessModel(data.id);

      return json(res);
    }
    default: {
      return null;
    }
  }
};

async function generateBusinessModel(
  data: GenerateBusinessModelInput
): Promise<ActionResponse<null>> {
  try {
    const thisMonthData = await getWorkspaceAnalyticsData(data.workspaceId);

    const checkRes = checkThisMonthDataAndCredit(thisMonthData, generateCanvasCreditConsume);

    if (checkRes) {
      return checkRes;
    }

    const auth = await getAuth();

    if (!auth?.idToken) {
      return {
        userMessage: {
          message: 'ログインしてください。',
          severity: 'error',
        },
        redirectPath: paths.auth.signIn,
      };
    }

    // NOTE: プロジェクトの作成
    const projectRes = (await client.models.Project.create({
      name: 'Business Model Canvas', // TODO: 仮置きなので修正する
      workspaceId: data.workspaceId,
      createdAt: new Date().toISOString(),
    })) as any;

    if (!projectRes) {
      throw new Error('Failed to create project');
    }

    // NOTE: ビジネスモデルキャンバスの生成
    const generateCanvasRes = await axios.post(
      'business-model-canvas',
      {
        organizationName: data?.organizationName || '',
        departmentName: data?.departmentName || '',
        description: data?.description || '',
        existingCustomers: data?.existingCustomers || '',
        valueProvidedToCustomers: data?.valueProvidedToCustomers || '',
        ownedResources: data?.ownedResources || '',
        issue: data?.issue || '',
        idea: data?.idea || '',
        initialCustomer: data?.initialCustomer || '',
        customerNeeds: data?.customerNeeds || '',
        solution: data?.solution || '',
        valueProposition: data?.valueProposition || '',
        channels: data?.channels || '',
        currentLangCountryCode: data.currentLangCountryCode,
        departures: data.departures || '',
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.idToken.toString()}`,
        },
      }
    );

    const generatedBusinessModelData = generateCanvasRes.data;

    // NOTE: ビジネスモデルキャンバスの保存
    const businessModelRes = await client.models.BusinessModel.create({
      workspaceId: data.workspaceId,
      projectId: projectRes.data.id,
      createdAt: new Date().toISOString(),
    });

    if (!businessModelRes) {
      throw new Error('Failed to create businessModel');
    }

    // NOTE: ビジネスモデルキャンバスの履歴の保存
    const businessModelHistoryRes = await client.models.BusinessModelHistory.create({
      title: generatedBusinessModelData?.data?.title || '',
      idea: generatedBusinessModelData?.data?.idea || '',
      businessModelId: businessModelRes?.data?.id || '',
      version: 1,
      owner: auth?.sub || '',
      workspaceId: data.workspaceId,
      sourceType: 'AI',
      createdAt: new Date().toISOString(),
      summarization: data.currentLangCountryCode === 'ja' ? '初期キャンバス' : 'Initial Canvas',
    });

    if (!businessModelHistoryRes) {
      throw new Error('Failed to create businessModelHistory');
    }

    const businessStatusHistoryRes = await client.models.BusinessStatusHistory.create({
      businessModelId: businessModelRes?.data?.id || '',
      organizationName: data.organizationName,
      organizationDepartmentName: data.departmentName,
      organizationDescription: data.description,
      organizationExistingCustomers: data.existingCustomers,
      organizationValueProvidedToCustomers: data.valueProvidedToCustomers,
      organizationOwnedResources: data.ownedResources,
      issue: data.issue,
      idea: data.idea,
      initialCustomer: data.initialCustomer,
      customerNeeds: data.customerNeeds,
      solution: data.solution,
      valueProposition: data.valueProposition,
      channels: data.channels,
      departures: generatedBusinessModelData?.data?.departure || [],
      workspaceId: data.workspaceId,
      createdAt: new Date().toISOString(),
    });

    if (!businessStatusHistoryRes) {
      throw new Error('Failed to create businessStatusHistory');
    }
    // NOTE: ビジネスモデルキャンバスのフィールドの保存
    const businessModelFieldsRes = await Promise.all(
      Object.values(BusinessModelFieldName)?.map((field) => {
        const convertedField = convertLlmOutputToMarkdownList(
          generatedBusinessModelData?.data[field] || ''
        );

        return client.models.BusinessModelField.create({
          businessModelHistoryId: businessModelHistoryRes?.data?.id || '',
          field,
          value: convertedField,
          changed: false,
          workspaceId: data.workspaceId,
        });
      })
    );

    if (!businessModelFieldsRes) {
      throw new Error('Failed to create businessModelFields');
    }

    // すでに今月に入ってビジネスモデルを生成している場合は、カウンターをインクリメントする
    const inputTokens = generatedBusinessModelData?.inputTokens || 0;
    const outputTokens = generatedBusinessModelData?.outputTokens || 0;

    await updateCredit(
      generateCanvasCreditConsume,
      thisMonthData,
      data.workspaceId,
      inputTokens,
      outputTokens
    );

    return {
      userMessage: {
        message: 'ビジネスモデルキャンバスを作成しました。',
        severity: 'success',
      },
      redirectPath: paths.workspace.edit(
        data.workspaceId,
        businessModelRes?.data?.id || '',
        businessModelHistoryRes?.data?.id || ''
      ),
    };
  } catch (err) {
    console.error(err);
    sentryCaptureError(err);

    return {
      userMessage: {
        message:
          '申し訳ございませんが、ただいま混雑しております。しばらく時間をおいて実行してください。',
        severity: 'error',
      },
    };
  }
}
