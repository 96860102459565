import type { Schema } from '@packages/gen2-shared-backend/amplify/data/resource';

import { defer, redirect } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { client } from 'src/utils/amplify-client-utils';
import { fetchAllData } from 'src/utils/fetch-all-data';

import { getWorkspaceAnalyticsData } from 'src/actions/workspace-analytics';

import { getAuth } from 'src/auth/context';

const listComments = async (businessModelId: string) => {
  // Comment
  const commentQuery = client.models.Comment.listCommentByBusinessModelIdAndCreatedAt;
  const commentParams = {
    businessModelId,
    sortDirection: 'DESC',
  };
  const commentsRes = await fetchAllData<
    Schema['Comment']['type'],
    Schema['Comment']['secondaryIndexes']['listCommentByBusinessModelIdAndCreatedAt']['input']
  >(commentQuery, commentParams);

  const res = await Promise.all(
    commentsRes.data.map(async (comment) => {
      const user = await client.queries.GetUserResolver({ id: comment.userId });
      return {
        ...comment,
        commentedUserName: `${user?.data?.familyName ?? ''} ${user?.data?.givenName ?? ''}`,
        commentedUserPicture: user?.data?.iconPath ?? '',
      };
    })
  );

  return {
    comments: res,
  };
};

const listBusinessModelHistories = async (businessModelId: string) => {
  const businessModelHistoriesQuery =
    client.models.BusinessModelHistory.listBusinessModelHistoryByBusinessModelIdAndVersion;
  const businessModelHistoriesParams = {
    businessModelId,
    sortDirection: 'DESC',
    limit: 1,
  };
  const _businessModelHistoriesData = await fetchAllData<
    Schema['BusinessModelHistory']['type'],
    Schema['BusinessModelHistory']['secondaryIndexes']['listBusinessModelHistoryByBusinessModelIdAndVersion']['input']
  >(businessModelHistoriesQuery, businessModelHistoriesParams);
  const res = await Promise.all(
    _businessModelHistoriesData.data.map(async (historyData) => {
      const userQuery = await client.queries.GetUserResolver({ id: historyData.owner });

      return {
        ...historyData,
        fullName: `${userQuery?.data?.familyName ?? ''} ${userQuery?.data?.givenName ?? ''}`,
        iconPath: userQuery?.data?.iconPath ?? '',
      };
    })
  );

  return {
    businessModelHistories: res,
  };
};

const listBusinessModelFields = async (businessModelHistoryId: string) => {
  // BusinessModelField
  const businessModelFieldsQuery =
    client.models.BusinessModelField.listBusinessModelFieldByBusinessModelHistoryIdAndField;
  const businessModelFieldsParams = {
    businessModelHistoryId,
  };
  const res = await fetchAllData<
    Schema['BusinessModelField']['type'],
    Schema['BusinessModelField']['secondaryIndexes']['listBusinessModelFieldByBusinessModelHistoryIdAndField']['input']
  >(businessModelFieldsQuery, businessModelFieldsParams);

  return {
    businessModelFields: res.data,
  };
};

async function getPlan(workspaceId?: string) {
  try {
    if (!workspaceId) {
      throw new Error('workspaceId is required');
    }

    const query = client.models.Plan.listPlanByWorkspaceId;

    const params = {
      workspaceId,
      filter: { status: { eq: 'Active' } },
    };

    const plans = await fetchAllData<
      Schema['Plan']['type'],
      Schema['Plan']['secondaryIndexes']['listPlanByWorkspaceId']['input']
    >(query, params);

    if (plans.data && plans.data.length > 0) {
      return { plan: plans.data[0] };
    }
    return undefined;
  } catch (err) {
    console.error('Error in getPlan:', err);
    return undefined;
  }
}

const getWorkspaceAnalysis = async (workspaceId?: string) => {
  if (!workspaceId) {
    throw new Error('workspaceId is required');
  }
  const thisMonthData = await getWorkspaceAnalyticsData(workspaceId);
  return { workspaceAnalysisData: thisMonthData };
};

async function getWorkspaceList(id: string) {
  const workspaceList = await client.models.Workspace.list({
    filter: { userIds: { contains: id }, isDeleted: { eq: false } },
  });

  return {
    workspaceList: workspaceList.data,
  };
}

async function getWorkspaceOwner(input: { id: Schema['Workspace']['type']['id'] }) {
  const { data } = await client.models.Workspace.get(input);
  return {
    workspaceOwner: data?.owner,
  };
}

async function listBusinessModelActionContents(businessModelId: string) {
  const query =
    client.models.BusinessModelActionContent
      .listBusinessModelActionContentByBusinessModelIdAndCreatedAt;
  const params = {
    businessModelId,
    sortDirection: 'DESC',
  };

  try {
    const res = await fetchAllData<
      Schema['BusinessModelActionContent']['type'],
      Schema['BusinessModelActionContent']['secondaryIndexes']['listBusinessModelActionContentByBusinessModelIdAndCreatedAt']['input']
    >(query, params);

    return {
      listBusinessModelActionContents: res.data || [],
    };
  } catch (err) {
    return {
      listBusinessModelActionContents: [],
    };
  }
}

async function getKpiItems(businessModelId: string) {
  const query = client.models.Kpi.listKpiByBusinessModelIdAndCreatedAt;
  const params = {
    businessModelId,
  };

  try {
    const res = await fetchAllData<
      Schema['Kpi']['type'],
      Schema['Kpi']['secondaryIndexes']['listKpiByBusinessModelIdAndCreatedAt']['input']
    >(query, params);

    return {
      kpiItems: res.data || [],
    };
  } catch (err) {
    return {
      kpiItems: [],
    };
  }
}

async function getBusinessStatusHistory(businessModelId: string) {
  const query =
    client.models.BusinessStatusHistory.listBusinessStatusHistoryByBusinessModelIdAndCreatedAt;
  const params = {
    businessModelId,
    sortDirection: 'DESC',
  };

  try {
    const res = await fetchAllData<
      Schema['BusinessStatusHistory']['type'],
      Schema['BusinessStatusHistory']['secondaryIndexes']['listBusinessStatusHistoryByBusinessModelIdAndCreatedAt']['input']
    >(query, params);

    return {
      businessStatusHistory: res?.data[0] || undefined,
    };
  } catch (err) {
    return {
      businessStatusHistory: undefined,
    };
  }
}

async function getOrganizations(workspaceId?: string) {
  const query = client.models.Organization.listOrganizationByWorkspaceIdAndCreatedAt;

  const params = {
    workspaceId: workspaceId ?? '',
    sortDirection: 'DESC',
  };

  const organizations = await fetchAllData<
    Schema['Organization']['type'],
    Schema['Organization']['secondaryIndexes']['listOrganizationByWorkspaceIdAndCreatedAt']['input']
  >(query, params);

  return { organizations: organizations?.data ?? [] };
}

async function listPersona(businessModelId: string) {
  const query = client.models.Persona.listPersonaByBusinessModelIdAndCreatedAt;
  const params = {
    businessModelId,
    sortDirection: 'DESC',
  };

  const personas = await fetchAllData<
    Schema['Persona']['type'],
    Schema['Persona']['secondaryIndexes']['listPersonaByBusinessModelIdAndCreatedAt']['input']
  >(query, params);

  return { personas: personas?.data ?? [] };
}

async function listJourneyAction(businessModelId: string) {
  const query = client.models.JourneyAction.listJourneyActionByBusinessModelIdAndCreatedAt;
  const params = {
    businessModelId,
    sortDirection: 'DESC',
    limit: 10,
  };

  const journeyAction = await fetchAllData<
    Schema['JourneyAction']['type'],
    Schema['JourneyAction']['secondaryIndexes']['listJourneyActionByBusinessModelIdAndCreatedAt']['input']
  >(query, params);

  return { journeyActions: journeyAction?.data ?? [] };
}

async function listJourneyActionsWithElements(businessModelId: string) {
  const journeyActionsResponse = await listJourneyAction(businessModelId);
  const { journeyActions } = journeyActionsResponse;

  const journeyActionElements = (
    await Promise.all(
      journeyActions.map(async (journeyAction) => {
        const journeyActionId = journeyAction.id;
        const query =
          client.models.JourneyActionElement.listJourneyActionElementByJourneyActionIdAndSequence;
        const params = {
          journeyActionId,
          sortDirection: 'DESC',
          limit: 100,
        };

        const journeyActionElementResponse = await fetchAllData<
          Schema['JourneyActionElement']['type'],
          Schema['JourneyActionElement']['secondaryIndexes']['listJourneyActionElementByJourneyActionIdAndSequence']['input']
        >(query, params);

        return journeyActionElementResponse?.data ?? [];
      })
    )
  ).flat();

  return {
    journeyActions,
    journeyActionElements,
  };
}

export const businessModelCanvasLoader = async (
  businessModelId?: string,
  businessModelHistoryId?: string,
  workspaceId?: string
) => {
  if (!businessModelId || !businessModelHistoryId || !workspaceId) {
    return redirect('/404');
  }

  const user = await getAuth();

  if (!user.sub) {
    return redirect(paths.auth.signIn);
  }

  sessionStorage.setItem('businessModelId', businessModelId);
  sessionStorage.setItem('businessModelHistoryId', businessModelHistoryId);

  const promiseBusinessModelDetail = Promise.all([
    listBusinessModelFields(businessModelHistoryId),
    listBusinessModelHistories(businessModelId),
    listComments(businessModelId),
    getPlan(workspaceId),
    getWorkspaceAnalysis(workspaceId),
    getWorkspaceList(user.sub),
    getWorkspaceOwner({ id: workspaceId }),
    listBusinessModelActionContents(businessModelId),
    getKpiItems(businessModelId),
    getBusinessStatusHistory(businessModelId),
    getOrganizations(workspaceId),
    listPersona(businessModelId),
    listJourneyActionsWithElements(businessModelId),
  ]);

  return defer({
    promiseBusinessModelDetail,
  });
};
